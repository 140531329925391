import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { translation } from '@hh.ru/front-static-app';
import { useBreakpoint } from '@hh.ru/magritte-ui';

import { selectApplicantResumeRecommendation } from 'src/models/applicant/resume/blocks/recommendation/selectors';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import { isEmpty } from 'src/models/applicant/resume/lib/common/empty';
import ResumeRecommendationEditor from 'src/pages/ResumeView/redesign/blocks/recommendation/Editor';
import DesktopCard from 'src/pages/ResumeView/redesign/common/ListCard/DesktopCard';
import MobileCard from 'src/pages/ResumeView/redesign/common/ListCard/MobileCard';

const TrlKeys = {
    add: 'applicant.resume.block.btn.mobile.add',
    label: 'applicant.resume.recommendation.label',
    listTitle: 'applicant.resume.recommendation.mobile.list.title',
    listDescription: 'applicant.resume.recommendation.mobile.list.description',
};

const ApplicantResumeRecommendation = translation(({ trls }) => {
    const dispatch = useDispatch();
    const { isMobile } = useBreakpoint();

    const recommendation = useSelector(selectApplicantResumeRecommendation);

    const handleAdd = () => dispatch(openResumeEditorModal('recommendation'));
    const handleEdit = (index: number) => dispatch(openResumeEditorModal('recommendation', { index }));

    const items = useMemo(
        () =>
            recommendation.map(({ id, name, organization, position }) => ({
                id,
                title: name,
                subtitle: position,
                description: organization,
            })),
        [recommendation]
    );

    return (
        <>
            {!isEmpty(recommendation) && (
                <>
                    {isMobile ? (
                        <MobileCard
                            onAdd={handleAdd}
                            onEdit={handleEdit}
                            items={items}
                            label={trls[TrlKeys.label]}
                            addText={trls[TrlKeys.add]}
                            listTitle={trls[TrlKeys.listTitle]}
                            listDescription={trls[TrlKeys.listDescription]}
                        />
                    ) : (
                        <DesktopCard onAdd={handleAdd} onEdit={handleEdit} items={items} label={trls[TrlKeys.label]} />
                    )}
                </>
            )}
            <ResumeRecommendationEditor />
        </>
    );
});

export default ApplicantResumeRecommendation;
