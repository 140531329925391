import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { BanOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import translation from 'src/components/translation';

const ResumeBlackList = ({ hide, trls }) => {
    if (hide) {
        return null;
    }

    return (
        <p className="resume-header-applicant-in-blacklist">
            <span className="blacklist-applicant">
                <BlokoIconReplaceContainer>
                    <BanOutlinedSize16 />
                </BlokoIconReplaceContainer>
                <span className="blacklist-applicant__text">{trls[ResumeBlackList.trls.text]}</span>
            </span>
        </p>
    );
};

ResumeBlackList.trls = {
    text: 'employer.blacklist.response.hidden',
};

ResumeBlackList.propTypes = {
    hide: PropTypes.bool,
    trls: PropTypes.object,
};

export default connect((state) => ({
    hide: !state.resume?.blacklistedByEmployer,
}))(translation(ResumeBlackList));
