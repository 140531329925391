import { Link as MagritteLink, Text, VSpacingContainer } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import formatDate from 'Modules/formatDate';
import translation from 'src/components/translation';
import { EmployerStateExtName } from 'src/models/negotiationTopic.types';
import { NegotiationsHistory } from 'src/models/negotiationsHistory';
import { ARROW_RIGHT, NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    author: 'resume.employer.history.author',
    title: 'resume.employer.history.title',
    expand: 'resume.employer.history.expand',
    collapse: 'resume.employer.history.collapse',
    loading: 'resume.employer.history.error.loading',
    [EmployerStateExtName.Response]: 'resume.employer.history.response',
    [EmployerStateExtName.Interview]: 'resume.employer.history.interview',
    [EmployerStateExtName.PhoneInterview]: 'resume.employer.history.phone_interview',
    [EmployerStateExtName.Assessment]: 'resume.employer.history.assessment',
    [EmployerStateExtName.Consider]: 'resume.employer.history.consider',
    [EmployerStateExtName.Hired]: 'resume.employer.history.hired',
    [EmployerStateExtName.Offer]: 'resume.employer.history.offer',
    [EmployerStateExtName.DiscardByEmployer]: 'resume.employer.history.discard_by_employer.softForm',
    [EmployerStateExtName.DiscardByApplicant]: 'resume.employer.history.discard_by_applicant',
    [EmployerStateExtName.DiscardNoInteraction]: 'resume.employer.history.discard_no_interaction',
    [EmployerStateExtName.DiscardVacancyClosed]: 'resume.employer.history.discard_vacancy_closed',
    [EmployerStateExtName.DiscardToOtherVacancy]: 'resume.employer.history.discard_to_other_vacancy',
};

interface ItemProp {
    vacancy: NegotiationsHistory;
}

const Item: TranslatedComponent<ItemProp> = ({ vacancy, trls }) => (
    <VSpacingContainer default={8} data-qa="resume-history-item">
        <MagritteLink
            Element={Link}
            data-qa="resume-history-item-text"
            to={`/vacancy/${vacancy['@vacancyId']}`}
            style="neutral"
            typography="paragraph-2-regular"
        >
            {vacancy['@name']}
        </MagritteLink>

        {vacancy.items.map((item, index) => (
            <Text
                Element="span"
                typography="paragraph-2-regular"
                style="secondary"
                data-qa="resume-history-item-info"
                key={index}
            >
                {ARROW_RIGHT}
                {NON_BREAKING_SPACE}
                {trls[TrlKeys[item['@employerStateExtName']]]}
                {', '}
                <span suppressHydrationWarning>{formatDate(item['@changeTime'], 'dd.MM.yy')}</span>
                {item['@senderEmployerManagerId'] && (
                    <>
                        {trls[TrlKeys.author]}
                        {': '}
                        <MagritteLink
                            inline
                            Element={Link}
                            to={`/employer/settings?employerManagerId=${item['@senderEmployerManagerId']}`}
                            typography="paragraph-2-regular"
                        >
                            {item['@senderFullName']}
                        </MagritteLink>
                    </>
                )}
            </Text>
        ))}
    </VSpacingContainer>
);

export default translation(Item);
