import { ComponentType } from 'react';

import { Card, Cell, CellText } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

interface Props {
    title: string;
    text: string;
    onClick: () => void;
}

const AdditionalCard: ComponentType<Props> = ({ title, text, onClick }) => {
    return (
        <Card actionCard stretched padding={16} borderRadius={16} showBorder onClick={onClick}>
            <Cell right={<ChevronRightOutlinedSize24 />}>
                <CellText>{title}</CellText>
                <CellText type="subtitle">{text}</CellText>
            </Cell>
        </Card>
    );
};

export default AdditionalCard;
