import { ComponentProps, FC, PropsWithChildren, forwardRef, ChangeEventHandler } from 'react';

import { CheckableCard, Text, TextTypography } from '@hh.ru/magritte-ui';

import styles from 'src/components/CheckablePlate/index.less';

const HORIZONTAL_PADDING = 16;
const VERTICAL_PADDING = 12;

const CenteredText = forwardRef<HTMLDivElement, PropsWithChildren>(({ children }, ref) => (
    <div className={styles.text} ref={ref}>
        {children}
    </div>
));

const CheckablePlate: FC<
    PropsWithChildren<{
        type: ComponentProps<typeof CheckableCard>['type'];
        name: string;
        checked?: boolean;
        value: string;
        onChange?: ChangeEventHandler<HTMLInputElement>;
        flexible?: boolean;
        typography?: TextTypography;
        disabled?: boolean;
    }>
> = ({ name, checked, children, type, onChange, value, flexible, typography = 'label-3-regular', disabled }) => (
    <CheckableCard
        type={type}
        borderRadius={12}
        paddingTop={VERTICAL_PADDING}
        paddingBottom={VERTICAL_PADDING}
        paddingLeft={flexible ? 0 : HORIZONTAL_PADDING}
        paddingRight={flexible ? 0 : HORIZONTAL_PADDING}
        checked={checked}
        name={name}
        onChange={onChange}
        value={value}
        flexible={flexible}
        disabled={disabled}
    >
        <CenteredText>
            <Text typography={typography}>{children}</Text>
        </CenteredText>
    </CheckableCard>
);

export default CheckablePlate;
