import { Button, Card, Text } from '@hh.ru/magritte-ui';
import BlokoButton from 'bloko/blocks/button';
import MenuItem from 'bloko/blocks/drop/Menu/Item';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ResumeShareModal from 'src/components/HiringManager/ResumeShareModal';
import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    share: 'employer.resume.button.share',
};

interface ShareResumeProps {
    type: 'button' | 'menuItem';
}

const ShareResume: TranslatedComponent<ShareResumeProps> = ({ trls, type }) => {
    const [isModalVisible, setOn, setOff] = useOnOffState(false);
    const { t: topicId } = useSelector((state) => state.router.location.query);
    const resume = useSelector((state) => state.resume);
    const resumeHash = resume?.hash;
    const resumeName = resume?.title.value;
    const isMagritte = useMagritte();

    const buttonControl = isMagritte ? (
        <Button mode="secondary" style="accent" size="small" onClick={setOn}>
            {trls[TrlKeys.share]}
        </Button>
    ) : (
        <BlokoButton onClick={setOn}>{trls[TrlKeys.share]}</BlokoButton>
    );

    const menuControl = isMagritte ? (
        <Card onClick={setOn} borderRadius={16} padding={12} paddingLeft={16} hoverStyle="neutral" actionCard stretched>
            <Text style="primary" typography="label-2-regular">
                {trls[TrlKeys.share]}
            </Text>
        </Card>
    ) : (
        <MenuItem onClick={setOn}>{trls[TrlKeys.share]}</MenuItem>
    );

    const control = type === 'button' ? buttonControl : menuControl;

    if (!resumeHash || !resumeName) {
        return null;
    }

    return (
        <>
            {control}
            <ResumeShareModal
                topicIds={[topicId].filter(Boolean)}
                resumeName={[resumeName]}
                resumeHash={[resumeHash]}
                visible={isModalVisible}
                setOff={setOff}
            />
        </>
    );
};

export default translation(ShareResume);
