import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import formatDate from 'Modules/formatDate';
import TagWithContrastText from 'src/components/TagWithContrastText';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    updates: 'employer.resume.updated',
};

const ResumeUpdateDate: TranslatedComponent = ({ trls }) => {
    const date = useSelector((state) => state.resume?.updated);

    if (!date) {
        return null;
    }

    return (
        <TagWithContrastText>
            {trls[TrlKeys.updates]}
            {NON_BREAKING_SPACE}
            <span suppressHydrationWarning>{formatDate(date, 'dd.MM.y')}</span>
        </TagWithContrastText>
    );
};

export default translation(ResumeUpdateDate);
