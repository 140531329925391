import { DefaultRootState } from 'react-redux';
import { Dispatch } from 'redux';

import { editorConfigMap } from 'src/models/applicant/resume/editor/configMap';
import {
    selectResumeCondition,
    selectResumeEditorType,
    selectResumeEditorSaving,
    selectResumeEditorFields,
} from 'src/models/applicant/resume/editor/store/selectors';
import { setResumeEditorFormErrors } from 'src/models/applicant/resume/editor/store/slice';
import { ApplicantResumeEditorFields, UpdateBodyType } from 'src/models/applicant/resume/editor/types';
import { isEmpty } from 'src/models/applicant/resume/lib/common/empty';

import { closeResumeEditor } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import { updateResume } from 'src/models/applicant/resume/editor/store/actions/updateResume';

export const submitResumeEditorData =
    () =>
    (dispatch: Dispatch, getState: () => DefaultRootState): void => {
        const saving = selectResumeEditorSaving(getState());
        if (saving) {
            return;
        }

        const type = selectResumeEditorType(getState());
        const config = editorConfigMap[type];

        if (!config.changed(getState())) {
            dispatch(closeResumeEditor());
            return;
        }

        const fields = selectResumeEditorFields(getState()) as Required<ApplicantResumeEditorFields>;
        const body = config.prepare(fields, getState()) as Required<UpdateBodyType>;

        dispatch(saveResumeEditorData(body));
    };

export function saveResumeEditorData(body: Required<UpdateBodyType>) {
    return (dispatch: Dispatch, getState: () => DefaultRootState): void => {
        const type = selectResumeEditorType(getState());
        const config = editorConfigMap[type];
        const conditions = selectResumeCondition(getState());

        const errors = config.validate(body, conditions);

        if (config.beforeSubmit) {
            config.beforeSubmit(getState(), errors);
        }

        if (!isEmpty(errors)) {
            dispatch(setResumeEditorFormErrors(errors));
            return;
        }

        void dispatch(updateResume(body));
    };
}
