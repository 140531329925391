import { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import chatEntryResumeContactsButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/chat/chat_entry_resume_contacts_button_click';
import chatEntryResumeContactsShow from '@hh.ru/analytics-js-events/build/xhh/employer/chat/chat_entry_resume_contacts_button_show';
import { ChatikContext } from '@hh.ru/chatik-integration';
import { Button as MagritteButton } from '@hh.ru/magritte-ui';
import { BubbleRoundOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import Button, { ButtonAppearance, ButtonIconPosition } from 'bloko/blocks/button';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import urlParser from 'bloko/common/urlParser';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import ElementShownAnchor from 'src/components/ElementShownAnchor';
import { selectorCanCommunicateWithApplicant } from 'src/components/HiringManager/utils/selectorCanCommunicateWithApplicant';
import ResumeBlockButtonWrapper from 'src/components/ResumeView/ResumeBlockButtonWrapper';
import translation from 'src/components/translation';
import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

const ResumeContactsChatButton = ({ isResumeViewMagritte = false, onContactsClick, trls }) => {
    const openChatik = useContext(ChatikContext)?.openChatik;
    const chatikIntegration = useSelector((state) => state.chatikIntegration);
    const location = useSelector((state) => state.router.location);
    const vacancyId = urlParser(location.search)?.params?.vacancyId?.[0];
    const resumeId = urlParser(location.search)?.params?.resumeId?.[0];
    const topicList = useSelector((state) => state.employerNegotiations.topicList);
    const canUseChat = useSelector(selectorCanCommunicateWithApplicant);
    const isNewBtnExp = useExperiment('in_app_call_emp');
    const isMagritte = isNewBtnExp || isResumeViewMagritte;

    const chatIdFromTopic =
        topicList && topicList.length
            ? topicList?.find((item) => item.vacancyId.toString() === vacancyId)?.chatId
            : null;

    // tempexp_PORTFOLIO-36442_start
    const resumeHash = useSelector(({ resume }) => resume?.hash);

    const [commonChatId, setCommonChatId] = useState(null);
    const [commonChatIdLoading, setCommonChatIdLoading] = useState(false);

    const isChatWithoutVacancyExp = useExperiment(
        'chat_without_vacancy',
        canUseChat && chatikIntegration && Boolean(resumeHash) && !chatIdFromTopic
    );
    // tempexp_PORTFOLIO-36442_end

    if (!isChatWithoutVacancyExp && (!canUseChat || !chatIdFromTopic || !chatikIntegration)) {
        return null;
    }

    const handleChatFromTopicOpen = () => {
        openChatik?.({
            chatId: chatIdFromTopic,
            view: 'compact',
            hhtmFromLabel: 'resume',
        });
        onContactsClick?.('chat');
        chatEntryResumeContactsButtonClick({ vacancyId, resumeId });
    };

    // tempexp_PORTFOLIO-36442_start
    const handleCommonChatOpen = () => {
        chatEntryResumeContactsButtonClick({ vacancyId, resumeId });

        if (commonChatId) {
            openChatik?.({
                chatId: commonChatId,
                view: 'compact',
            });

            return;
        }

        setCommonChatIdLoading(true);

        fetcher
            .put('/shards/resume/chat_without_vacancy', { resume: resumeHash ?? '' })
            .then((data) => {
                setCommonChatId(data.data.chat_id);
                openChatik({ chatId: data.data.chat_id });
            })
            .finally(() => setCommonChatIdLoading(false));
    };

    const magritteButton = (
        <MagritteButton
            onClick={isChatWithoutVacancyExp ? handleCommonChatOpen : handleChatFromTopicOpen}
            mode="secondary"
            size="small"
            style="neutral"
            icon={isResumeViewMagritte ? <BubbleRoundOutlinedSize16 /> : undefined}
        >
            {trls[ResumeContactsChatButton.trls.text]}
        </MagritteButton>
    );

    const renderMagritteButton = () =>
        isResumeViewMagritte ? (
            <ResumeBlockButtonWrapper>{magritteButton}</ResumeBlockButtonWrapper>
        ) : (
            <>{magritteButton}</>
        );

    return (
        <>
            {isMagritte ? (
                renderMagritteButton()
            ) : (
                // tempexp_PORTFOLIO-36442_next_line
                <ElementShownAnchor fn={chatEntryResumeContactsShow} vacancyId={vacancyId} resumeId={resumeId}>
                    <Button
                        // tempexp_PORTFOLIO-36442_next_line
                        onClick={isChatWithoutVacancyExp ? handleCommonChatOpen : handleChatFromTopicOpen}
                        loading={commonChatIdLoading && <Loading scale={LoadingScale.Small} />}
                        icon={
                            <BlokoIconReplaceContainer>
                                <BubbleRoundOutlinedSize16 />
                            </BlokoIconReplaceContainer>
                        }
                        iconPosition={ButtonIconPosition.Right}
                        appearance={ButtonAppearance.Outlined}
                    >
                        {trls[ResumeContactsChatButton.trls.text]}
                    </Button>
                </ElementShownAnchor>
                // tempexp_PORTFOLIO-36442_end
            )}
        </>
    );
};

ResumeContactsChatButton.trls = {
    text: 'chatik.activator.resume',
};

ResumeContactsChatButton.propTypes = {
    onContactsClick: PropTypes.func,
    trls: PropTypes.object,
};

export default translation(ResumeContactsChatButton);
