import { useMemo } from 'react';

import { translation } from '@hh.ru/front-static-app';
import { Input, VSpacingContainer } from '@hh.ru/magritte-ui';

import {
    usePhoneChangedByIndex,
    useResumeContactEditor,
} from 'src/models/applicant/resume/blocks/contacts/editor/change';
import { PhoneType } from 'src/models/applicant/resume/blocks/contacts/types';
import { ErrorsMap } from 'src/models/applicant/resume/editor/form/errors';
import PhoneFormatter from 'src/pages/ResumeView/redesign/blocks/contacts/Editor/Phone/Formatter';
import PhoneProxy from 'src/pages/ResumeView/redesign/blocks/contacts/Editor/Phone/Proxy';
import PreferredContact from 'src/pages/ResumeView/redesign/blocks/contacts/Editor/PreferredContact';
import EditorField from 'src/pages/ResumeView/redesign/common/Form/EditorField';

interface Props {
    type: PhoneType;
    value?: string;
    comment?: string;
    errorsMap: ErrorsMap;
    fieldIndex: number;
}

const TrlKeys = {
    hint: 'resume.editor.contacts.phone.verified',
    label: {
        cell: 'resume.editor.contacts.label.phone.cell',
        work: 'resume.editor.contacts.label.phone.work',
        home: 'resume.editor.contacts.label.phone.home',
    },
    placeholder: {
        phone: 'resume.editor.contacts.placeholder.phone',
        comment: 'resume.editor.contacts.placeholder.comment',
    },
    INVALID: 'resume.editor.field.phone.error.invalid',
    NEED_COUNTRY_CITY_NUMBER_OR_FORMATTED: 'resume.editor.field.error.required',
};

const PhoneField = translation<Props>(({ trls, type, fieldIndex, errorsMap }) => {
    const errors = useMemo(
        () => ({
            ...errorsMap,
            INVALID: trls[TrlKeys.INVALID],
            NEED_COUNTRY_CITY_NUMBER_OR_FORMATTED: trls[TrlKeys.NEED_COUNTRY_CITY_NUMBER_OR_FORMATTED],
        }),
        [errorsMap, trls]
    );

    const phone = usePhoneChangedByIndex(fieldIndex, errors);

    const comment = useResumeContactEditor('phone', fieldIndex, 'comment', errors);
    const formatted = useResumeContactEditor('phone', fieldIndex, 'formatted', errors);

    const handleFormattedChange = (value: string) => {
        phone.resetError();
        formatted.onChange(value);
    };

    return (
        <EditorField htmlFor={type} label={trls[TrlKeys.label[type]]}>
            <VSpacingContainer default={12}>
                <PreferredContact value={`${type}_phone`} disabled={!formatted.value} />
                {type === 'cell' ? (
                    <PhoneProxy
                        phone={phone.value}
                        placeholder={trls[TrlKeys.placeholder.phone]}
                        onChange={phone.onChange}
                    />
                ) : (
                    <PhoneFormatter
                        type={type}
                        value={formatted.value}
                        invalid={formatted.invalid || phone.invalid}
                        onChange={handleFormattedChange}
                        placeholder={trls[TrlKeys.placeholder.phone]}
                        errorMessage={formatted.errorMessage || phone.errorMessage}
                    />
                )}
                <Input
                    size="medium"
                    value={comment.value ?? ''}
                    invalid={comment.invalid}
                    onChange={comment.onChange}
                    errorMessage={comment.errorMessage}
                    placeholder={trls[TrlKeys.placeholder.comment]}
                />
            </VSpacingContainer>
        </EditorField>
    );
});

export default PhoneField;
