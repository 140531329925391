import { Fragment } from 'react';

import { translation } from '@hh.ru/front-static-app';

import ConversionDateInterval from 'src/components/Conversion/DateInterval';
import MonthName from 'src/components/HumanDate/MonthName';
import { splitDate } from 'src/models/applicant/resume/blocks/experience/lib';
import { Interval } from 'src/models/resume/resumeCommon.types';

interface Props {
    startDate: string | null;
    endDate: string | null;
    interval?: Interval;
}

const TrlKeys = {
    tillToday: 'applicant.resume.experience.tillToday',
};

const ResumeExperienceInterval = translation<Props>(({ trls, startDate, endDate, interval }) => {
    const start = splitDate(startDate);
    const end = splitDate(endDate);

    if (!interval) {
        return null;
    }

    return (
        <Fragment>
            {start && (
                <Fragment>
                    <MonthName month={start.month} />
                    &nbsp;
                    {start.year}
                    {' — '}
                </Fragment>
            )}
            {end ? (
                <Fragment>
                    <MonthName month={end.month} />
                    &nbsp;
                    {end.year}
                </Fragment>
            ) : (
                trls[TrlKeys.tillToday]
            )}
            {interval && (
                <Fragment>
                    {' ('}
                    <ConversionDateInterval years={interval.years} months={interval.months} />
                    {')'}
                </Fragment>
            )}
        </Fragment>
    );
});

export default ResumeExperienceInterval;
