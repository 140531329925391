import { memo } from 'react';

import { Link, Text, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { BubbleSquareOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { getPreferredPhoneType, CELL_PHONE_TYPE } from 'src/components/ResumeContacts/preferredContacts';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { PhoneType } from 'src/models/applicant/resume/blocks/contacts/types';
import { HiddenFields } from 'src/models/resume/resumeCommon.types';
import { RESUME_BLOCK_VSPACING } from 'src/pages/ResumeView/employerRedesign/const';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import PhoneTypeAndPreference from 'src/pages/ResumeView/employerRedesign/components/ResumeContacts/PhoneTypeAndPreference';
import PhoneVerified from 'src/pages/ResumeView/employerRedesign/components/ResumeContacts/PhoneVerified';

import styles from './resumeContactPhones.less';

const TrlKeys = {
    isHidden: 'resume.hiddenFields.phones',
};
const ResumeContactsPhones: TranslatedComponent = ({ trls }) => {
    const isHidden = useSelector(({ resume }) => resume?.hiddenFields?.includes(HiddenFields.Phones));
    const phones = useSelector(({ resume }) => resume?.phone.value) || [];
    const preferredType = useSelector(({ resume }) => getPreferredPhoneType(resume?.preferredContact?.value || ''));

    if (isHidden) {
        return (
            <Text style="secondary" typography="label-3-regular">
                {trls[TrlKeys.isHidden]}
            </Text>
        );
    }

    return (
        <VSpacingContainer default={RESUME_BLOCK_VSPACING}>
            {phones.map((phone) => {
                const isPreferred = !!preferredType && phone.type === preferredType;
                return (
                    <div key={phone.type} data-qa="resume-serp_resume-item-content">
                        <PhoneTypeAndPreference isPreferred={isPreferred} type={phone.type as PhoneType} />
                        <VSpacing default={4} />
                        <div className={styles.phoneContainer}>
                            {phone.type === CELL_PHONE_TYPE && phone.verified && <PhoneVerified />}
                            <Link
                                typography="label-2-regular"
                                style="neutral"
                                href={`tel:+${phone.raw}`}
                                inline
                                data-qa={isPreferred ? 'resume-contact-preferred' : undefined}
                            >
                                {phone.formatted}
                            </Link>
                            {NON_BREAKING_SPACE}
                            {phone.comment && (
                                <div className={styles.comment}>
                                    <span className={styles.iconWrapper}>
                                        <BubbleSquareOutlinedSize16 initial="secondary" />
                                    </span>
                                    <Text Element="span" style="secondary" typography="label-3-regular">
                                        {phone.comment}
                                    </Text>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </VSpacingContainer>
    );
};

export default translation(memo(ResumeContactsPhones));
