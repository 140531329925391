import { DefaultRootState } from 'react-redux';

import { ApplicantServices } from 'src/components/ApplicantServices/common';

export const selectUpdatedTimeMs = ({ applicantResume }: DefaultRootState): number =>
    applicantResume._attributes.updated;

export const selectUpdatedTimeoutMs = ({ applicantResume }: DefaultRootState): number =>
    applicantResume._attributes.update_timeout;

export const selectResumeRenewalServiceExpireTime = ({ applicantResume }: DefaultRootState): string =>
    applicantResume._attributes.renewalServiceExpireTime;

export const selectHasRenewalService = (state: DefaultRootState): boolean =>
    state.applicantPaymentServices.includes(ApplicantServices.ResumeRenewal);

export const selectIsRenewal = ({ applicantResume }: DefaultRootState): boolean => applicantResume._attributes.renewal;
