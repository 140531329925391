import { useSelector } from 'src/hooks/useSelector';

export const splitStringDate = (date: string) => {
    const [year, month, day] = date.split('-').map(Number);
    return { year, month, day };
};

export const useResumeApplicantFullName = () => {
    const firstName = useSelector((state) => state.resume?.firstName.value);
    const middleName = useSelector((state) => state.resume?.middleName.value);
    const lastName = useSelector((state) => state.resume?.lastName.value);

    return [lastName, firstName, middleName].filter(Boolean).join(' ');
};
