import { DefaultRootState } from 'react-redux';

import { ApplicantResumeEditorFields, EditorFormType } from 'src/models/applicant/resume/editor/types';
import { PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { EditorFormErrors } from 'src/models/applicant/resume/editor/types/error';
import { Condition } from 'src/models/applicant/resume/types/conditions';
import { ResumeConditions } from 'src/models/applicant/resume/types/resume';

export const selectResumeEditorType = ({ resumeEditor }: DefaultRootState): EditorFormType => resumeEditor.type;

export const selectResumeEditorSaving = ({ resumeEditor }: DefaultRootState): boolean => resumeEditor.saving;
export const selectResumeEditorOpened = ({ resumeEditor }: DefaultRootState): boolean => resumeEditor.opened;
export const selectResumeEditorLoading = ({ resumeEditor }: DefaultRootState): boolean => resumeEditor.loading;

export const selectResumeEditorPayload = ({ resumeEditor }: DefaultRootState): PayloadType => resumeEditor.payload;

export const selectResumeEditorFields = ({ resumeEditor }: DefaultRootState): ApplicantResumeEditorFields =>
    resumeEditor.fields;

export const selectResumeEditorErrors = ({ resumeEditor }: DefaultRootState): EditorFormErrors => resumeEditor.errors;

export const selectResumeEditorOpenedType =
    (type: EditorFormType) =>
    ({ resumeEditor }: DefaultRootState): boolean =>
        resumeEditor.opened && resumeEditor.type === type;

export const selectResumeCondition = ({ applicantResume }: DefaultRootState): ResumeConditions =>
    applicantResume._conditions;

export const selectResumeConditionByKey =
    (key: keyof ApplicantResumeEditorFields) =>
    ({ applicantResume }: DefaultRootState): Condition | undefined =>
        applicantResume._conditions[key];
