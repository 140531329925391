import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { PhoneType } from 'src/models/applicant/resume/blocks/contacts/types';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

interface PhoneTypeProps {
    isPreferred: boolean;
    type?: PhoneType;
}

const TrlKeys = {
    isPreferred: 'rb.view.preferredcontact',
    [PhoneType.Cell]: 'resumeView.phoneType.mobile',
    [PhoneType.Work]: 'resumeView.phoneType.work',
    [PhoneType.Home]: 'resumeView.phoneType.home',
};

const PhoneTypeAndPreference: TranslatedComponent<PhoneTypeProps> = ({ trls, type, isPreferred }) => {
    return (
        <Text style="secondary" typography="label-3-regular">
            {type && trls[TrlKeys[type]]}
            {isPreferred && <>{`${NON_BREAKING_SPACE}— ${trls[TrlKeys.isPreferred]}`}</>}
        </Text>
    );
};

export default translation(PhoneTypeAndPreference);
