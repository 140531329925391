import { Text, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ResumePhoneDeepLinks from 'src/components/ResumePhoneDeepLinks';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { Phone } from 'src/models/resumeCard';
import { RESUME_BLOCK_VSPACING } from 'src/pages/ResumeView/employerRedesign/const';
import { OTHER_CONTACTS } from 'src/utils/constants/resumeHiddenFields';

import SocialMediaLinks from 'src/pages/ResumeView/employerRedesign/components/ResumeContacts/SocialMediaLinks';

const TrlKeys = {
    eventual: 'rb.personalsites.eventual',
};

interface ResumeContactsPersonalSitesProps {
    onContactsClick?: (triggerName: string) => void;
}

const ResumeContactsPersonalSites: TranslatedComponent<ResumeContactsPersonalSitesProps> = ({
    onContactsClick,
    trls,
}) => {
    const {
        phone,
        id: resumeId,
        simHash,
        created,
        hash: resumeHash,
        hiddenFields,
    } = useSelectorNonNullable(({ resume }) => resume);
    const shouldHideSites = hiddenFields.includes(OTHER_CONTACTS);
    const shouldShowDeepLinks = !!phone.value?.[0]?.raw;

    if (shouldHideSites) {
        return null;
    }
    return (
        <VSpacingContainer default={RESUME_BLOCK_VSPACING}>
            <SocialMediaLinks />
            {shouldShowDeepLinks && (
                <div>
                    <Text style="secondary" typography="label-3-regular">
                        {trls[TrlKeys.eventual]}
                    </Text>
                    <VSpacing default={4} />
                    {phone.value && (
                        <ResumePhoneDeepLinks
                            isMagritte
                            phone={phone.value[0] as Phone}
                            resumeId={resumeId}
                            simHash={simHash}
                            created={created}
                            resumeHash={resumeHash}
                            fromResumeView={true}
                            onContactsClick={onContactsClick}
                        />
                    )}
                </div>
            )}
        </VSpacingContainer>
    );
};

export default translation(ResumeContactsPersonalSites);
