import { useMemo } from 'react';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Button, Text } from '@hh.ru/magritte-ui';
import {
    LinkedinSolidSize16,
    FacebookSolidSize16,
    SkypeSolidSize16,
    FlSolidSize16,
    LivejournalSolidSize16,
    MediumSolidSize16,
} from '@hh.ru/magritte-ui/service';

import {
    shiftPersonalSiteTypeToEnd,
    PERSONAL_TYPE,
    getItemsToAdd,
} from 'src/models/applicant/resume/blocks/contacts/lib';
import { selectResumePersonalSiteType } from 'src/models/applicant/resume/blocks/contacts/selectors';
import { PersonalSite } from 'src/models/applicant/resume/blocks/contacts/types';
import { useResumeEditorField } from 'src/models/applicant/resume/editor/form/change';
import ButtonsWrapper from 'src/pages/ResumeView/redesign/blocks/contacts/Editor/ButtonsWrapper';
import { FIELD_NAME } from 'src/pages/ResumeView/redesign/blocks/contacts/Editor/PersonalSite/const';

export const SOCIAL_ICON = {
    linkedin: <LinkedinSolidSize16 />,
    facebook: <FacebookSolidSize16 />,
    skype: <SkypeSolidSize16 />,
    freelance: <FlSolidSize16 />,
    livejournal: <LivejournalSolidSize16 />,

    moi_krug: <MediumSolidSize16 />,
};

const TrlKeys = {
    add: 'resume.editor.contacts.add',
    personalSite: {
        freelance: 'resume.editor.personalSite.field.freelance',
        personal: 'resume.editor.personalSite.field.personal',
        livejournal: 'resume.editor.personalSite.field.livejournal',
        linkedin: 'resume.editor.personalSite.field.linkedin',

        moi_krug: 'resume.editor.personalSite.field.moi_krug',
        facebook: 'resume.editor.personalSite.field.facebook',
        skype: 'resume.editor.personalSite.field.skype',
    },
} as const;

const PersonalSiteAdd = translation(({ trls }) => {
    const types = useSelector(selectResumePersonalSiteType);

    const { value: personalSite, onChange } = useResumeEditorField(FIELD_NAME);

    const handleAddPersonalSite = (newSite: PersonalSite) => {
        onChange([...personalSite, newSite]);
    };

    const sortedTypes = useMemo(() => shiftPersonalSiteTypeToEnd(types, PERSONAL_TYPE), [types]);
    const additionTypes = useMemo(() => getItemsToAdd(sortedTypes, personalSite), [personalSite, sortedTypes]);

    if (additionTypes.length === 0) {
        return null;
    }

    return (
        <ButtonsWrapper>
            <Text typography="label-3-regular">{trls[TrlKeys.add]}</Text>
            {additionTypes.map((type) => {
                const isSocialType = type !== PERSONAL_TYPE;
                return (
                    <Button
                        key={type}
                        data-qa="add-social-link"
                        aria-label={trls[TrlKeys.personalSite[type]]}
                        size="small"
                        style="neutral"
                        mode="secondary"
                        hideLabel={isSocialType}
                        icon={isSocialType ? SOCIAL_ICON[type] : <></>}
                        onClick={() => handleAddPersonalSite({ type, url: '' })}
                    >
                        {trls[TrlKeys.personalSite.personal]}
                    </Button>
                );
            })}
        </ButtonsWrapper>
    );
});

export default PersonalSiteAdd;
