import { useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from '@hh.ru/front-static-app';
import { Select, TooltipHover } from '@hh.ru/magritte-ui';

import translation from 'src/components/translation';
import { submitResumeLanguage } from 'src/models/applicant/resume/blocks/languages/actions';
import { selectResumeLanguages } from 'src/models/applicant/resume/blocks/languages/selectors';
import { selectResumeLang } from 'src/models/applicant/resume/selectors';
import { SiteLanguages } from 'src/models/locale.types';

import styles from './styles.less';

const TrlKeys = {
    RU: 'topbuttons.resume.lang.RU',
    EN: 'topbuttons.resume.lang.EN',
    UA: 'topbuttons.resume.lang.UA',
    AZ: 'topbuttons.resume.lang.AZ',
    KZ: 'topbuttons.resume.lang.KZ',
    UZ: 'topbuttons.resume.lang.UZ',
    KG: 'topbuttons.resume.lang.KG',
    label: 'applicant.resume.lang.label',
};

const LanguageSelector = translation(({ trls }) => {
    const dispatch = useDispatch();

    const languages = useSelector(selectResumeLanguages);
    const currentLanguage = useSelector(selectResumeLang);

    const activatorRef = useRef<HTMLDivElement>(null);

    const options = useMemo(
        () =>
            languages.map((language) => ({
                value: language,
                label: trls[TrlKeys[language]],
            })),
        [languages, trls]
    );

    const handleLanguageChange = (lang: string) => {
        void dispatch(submitResumeLanguage(lang as SiteLanguages));
    };

    return (
        <div ref={activatorRef} className={styles.root}>
            <Select
                plain
                type="radio"
                name="language"
                options={options}
                triggerProps={{
                    label: trls[TrlKeys.label],
                    size: 'small',
                    stretched: true,
                }}
                value={currentLanguage}
                onChange={handleLanguageChange}
                widthEqualToActivator={false}
            />
            <TooltipHover placement="top-center" activatorRef={activatorRef}>
                {trls[TrlKeys.label]}
            </TooltipHover>
        </div>
    );
});

export default LanguageSelector;
