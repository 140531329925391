import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { usePush } from '@hh.ru/redux-spa-middleware';

import ResumeCompleteness from 'src/components/ResumeCompleteness';
import {
    selectResumeFieldPercent,
    selectResumeFieldStatuses,
} from 'src/models/applicant/resume/completeness/selectors';
import {
    EDITOR_QUERY_KEY,
    getBlockTypeByField,
    getProfileBlockTypeByField,
} from 'src/models/applicant/resume/completeness/utils';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';

const TrlKeys = {
    description: 'resume.completeness.description',
};

const ResumeCompletenessCard = translation(({ trls }) => {
    const push = usePush();
    const dispatch = useDispatch();
    const percent = useSelector(selectResumeFieldPercent);
    const { redFields, greenFields } = useSelector(selectResumeFieldStatuses);

    const fields = redFields.length ? redFields : greenFields;

    const handleClick = (field: string) => {
        const profileType = getProfileBlockTypeByField(field);
        if (profileType) {
            push(`/profile/me?${EDITOR_QUERY_KEY}=${profileType}`);
            return;
        }

        const type = getBlockTypeByField(field);
        if (type) {
            void dispatch(openResumeEditorModal(type));
        }
    };

    if (percent === 100) {
        return null;
    }
    return (
        <ResumeCompleteness
            max={100}
            fields={fields}
            onClick={handleClick}
            progress={percent}
            description={trls[TrlKeys.description]}
        />
    );
});

export default ResumeCompletenessCard;
