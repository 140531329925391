import { memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import { SquaresIntersectedSize16, IconDynamic } from '@hh.ru/magritte-ui/icon';
import {
    FacebookSolidSize16,
    LinkedinSolidSize16,
    IcqSolidSize16,
    SkypeSolidSize16,
    FlSolidSize16,
    LivejournalSolidSize16,
    MediumSolidSize16,
} from '@hh.ru/magritte-ui/service';
import BlokoLink from 'bloko/blocks/link';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import translation from 'src/components/translation';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

export const sendAnalyticsOnSocialLinkClick = () => {
    Analytics.sendEvent('employer', 'resume-with-social-link-click');
};

const PERSONAL_SITE_ICON = {
    personal: SquaresIntersectedSize16,
    linkedin: LinkedinSolidSize16,
    facebook: FacebookSolidSize16,
    icq: IcqSolidSize16,
    skype: SkypeSolidSize16,
    freelance: FlSolidSize16,
    livejournal: LivejournalSolidSize16,
    moi_krug: MediumSolidSize16,
};

const ResumeContactsSocialSite = ({ site: { type, text, url, isPreferred }, trls }) => {
    const contentText = text || trls[ResumeContactsSocialSite.trls[type]];
    const IconView = PERSONAL_SITE_ICON[type];

    if (url) {
        return (
            <span className="resume-header-contact">
                <BlokoLink
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={sendAnalyticsOnSocialLinkClick}
                >
                    <IconDynamic>
                        <span className="resume__contacts-links">
                            <span
                                className={classnames(`resume-contact-${type}`, {
                                    [`resume-contact-${type}_preferred`]: isPreferred,
                                })}
                                data-qa={`resume-personalsite-${type}`}
                            >
                                <BlokoIconReplaceContainer>
                                    <IconView initial="primary" highlighted="secondary" />
                                </BlokoIconReplaceContainer>
                                {NON_BREAKING_SPACE}
                                {contentText}
                            </span>
                        </span>
                    </IconDynamic>
                </BlokoLink>
                {isPreferred && (
                    <span className="resume-contacts-preferred-hint">
                        &nbsp;— {trls[ResumeContactsSocialSite.trls.isPreferred]}
                    </span>
                )}
            </span>
        );
    }

    return (
        <span className="resume-header-contact" data-qa={`resume-personalsite-${type}`}>
            <BlokoIconReplaceContainer>
                <IconView initial="primary" highlighted="secondary" />
            </BlokoIconReplaceContainer>
            {NON_BREAKING_SPACE}
            {contentText}
        </span>
    );
};

ResumeContactsSocialSite.trls = {
    icq: 'rb.personalsites.icq',
    skype: 'rb.personalsites.skype',
    facebook: 'rb.personalsites.facebook',
    moi_krug: 'rb.personalsites.moi_krug',
    freelance: 'rb.personalsites.freelance',
    livejournal: 'rb.personalsites.livejournal',
    linkedin: 'rb.personalsites.linkedin',
    personal: 'rb.personalsites.personal',
    isPreferred: 'resume.view.preferredcontact',
};

ResumeContactsSocialSite.propTypes = {
    site: PropTypes.object,
    trls: PropTypes.object,
};

export default memo(translation(ResumeContactsSocialSite));
