import { selectApplicantResumeRecommendation } from 'src/models/applicant/resume/blocks/recommendation/selectors';
import { validateEditorFieldByKey } from 'src/models/applicant/resume/editor/form/validate/index';
import { isChangedByKeys, isNewArrayField, updateItemById } from 'src/models/applicant/resume/editor/lib';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorConfig, PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { Recommendation } from 'src/models/resume/resumeCommon.types';

import {
    isNewRecomendationChanged,
    getSelctedRecomendationByIndex,
} from 'src/models/applicant/resume/blocks/recommendation/editor/lib';
import { selectResumeEditorRecommendation } from 'src/models/applicant/resume/blocks/recommendation/editor/selectors';

export const RECOMMENDATION_FIELD = 'recommendation';
type RecommendationFields = typeof RECOMMENDATION_FIELD;

export type RecommendationBlockConfig = EditorConfig<Pick<ApplicantResumeEditorFields, RecommendationFields>>;

const isRecommendationChanges = isChangedByKeys<Recommendation>(['name', 'organization', 'position']);

export const recommendationConfig: RecommendationBlockConfig = {
    init: (store) => {
        return {
            recommendation: getSelctedRecomendationByIndex(store),
        };
    },

    changed: (store) => {
        const editorRecommendation = selectResumeEditorRecommendation(store);
        const resumeRecommendations = selectApplicantResumeRecommendation(store);

        if (!editorRecommendation) {
            return false;
        }

        if (isNewArrayField(editorRecommendation)) {
            return isNewRecomendationChanged(editorRecommendation);
        }

        const resumeRecomendation = resumeRecommendations.find(({ id }) => id === editorRecommendation.id);
        if (!resumeRecomendation) {
            return false;
        }
        return isRecommendationChanges(resumeRecomendation, editorRecommendation);
    },

    prepare: ({ recommendation }, store) => {
        const resumeRecommendation = selectApplicantResumeRecommendation(store);

        if (isNewArrayField(recommendation)) {
            return { recommendation: [...resumeRecommendation, recommendation] };
        }

        return {
            recommendation: updateItemById(resumeRecommendation, recommendation),
        };
    },

    validate: ({ recommendation }, conditions) => ({
        ...validateEditorFieldByKey('recommendation', recommendation, conditions),
    }),

    delete: (store, payload) => {
        const recommendation = selectApplicantResumeRecommendation(store);
        const { index } = payload as PayloadType<{ index: number }>;
        return { recommendation: recommendation.filter((_, i) => i !== index) };
    },
};
