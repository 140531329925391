import { FC } from 'react';

import { Cell, CellText } from '@hh.ru/magritte-ui';

import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';

interface ResumeEducationItemProps {
    name: string;
    year: number;
    organization?: string;
    result?: string;
    link?: string;
}

const ResumeEducationItem: FC<ResumeEducationItemProps> = ({ name, year, organization, result, link }) => {
    return (
        <Cell
            {...(link && {
                Element: 'a',
                href: link,
                target: '_blank',
            })}
        >
            <CellText type="title">
                <AsyncHighlighter>{name}</AsyncHighlighter>
            </CellText>
            {(organization || result) && (
                <CellText type="subtitle">
                    <AsyncHighlighter>{[organization, result].filter(Boolean).join(', ')}</AsyncHighlighter>
                </CellText>
            )}
            <CellText type="subtitle">{year}</CellText>
        </Cell>
    );
};

export default ResumeEducationItem;
