import { DefaultRootState } from 'react-redux';

import { selectResumeExperience } from 'src/models/applicant/resume/blocks/experience/selectors';
import { NEW_ID } from 'src/models/applicant/resume/editor/lib';
import { selectResumeEditorPayload } from 'src/models/applicant/resume/editor/store/selectors';
import { PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { Experience } from 'src/models/resume/resumeCommon.types';

export const getNewExperience = (): Partial<Experience> => ({
    id: NEW_ID,
    position: '',
    description: '',
    startDate: '',
    endDate: '',
    companyName: 'hh', // времмено указал, тк поле обязательно при редактировании
});

export const getSelectedExperienceByIndex = (store: DefaultRootState): Experience => {
    const { index } = selectResumeEditorPayload(store) as PayloadType<{ index: number }>;

    const experiences = selectResumeExperience(store);

    const experience = experiences[index];

    if (experience) {
        return {
            ...experience,
            endDate: experience.endDate === null ? '' : experience.endDate,
        };
    }

    return experiences[index] || getNewExperience();
};

export const isNewExperienceChanged = ({ position, description, startDate, endDate }: Partial<Experience>): boolean =>
    position !== '' || description !== '' || startDate !== '' || endDate !== '';

export const transformExperienceToSave = (experience: Experience[]) => {
    return experience.map(({ companyLogos, interval, ...fields }) => ({ ...fields })) as Experience[];
};
