import { translation } from '@hh.ru/front-static-app';
import { Input, Placeholder, Suggest } from '@hh.ru/magritte-ui';

import { PositionDTO, usePositionDataProvider } from 'src/models/applicant/resume/api/position';
import { useResumeEditorListField } from 'src/models/applicant/resume/editor/form/change';
import { ExperienceFieldProps } from 'src/pages/ResumeView/redesign/experience/Editor/types';

const TrlKeys = {
    titlePlaceholder: 'resume.editor.experience.position.title.placeholder',
    errorTitle: 'resume.editor.suggest.errors.placeholder',
    resetButton: 'resume.editor.suggest.errors.button',
    errorDescription: 'resume.editor.suggest.errors.description',
};

const PositionField = translation<ExperienceFieldProps>(({ trls, errorsMap, index, fieldName }) => {
    const { value, onChange, invalid, errorMessage } = useResumeEditorListField(
        fieldName,
        index,
        'position',
        errorsMap
    );

    const dataProvider = usePositionDataProvider();

    const handleChange = (value: string, isValueFromList: boolean) => {
        if (!isValueFromList) {
            onChange(value);
        }
    };

    const handleSelect = (_value: string, item?: PositionDTO) => {
        if (!item) {
            return true;
        }

        onChange(item.text);

        return true;
    };

    return (
        <Suggest
            onChange={handleChange}
            dataProvider={dataProvider}
            onSelectValidator={handleSelect}
            navigationBarProps={{
                title: trls[TrlKeys.titlePlaceholder],
            }}
            input={{
                component: Input,
                props: {
                    value,
                    size: 'medium',
                    invalid,
                    clearable: true,
                    placeholder: trls[TrlKeys.titlePlaceholder],
                    errorMessage,
                },
            }}
            trls={{
                resetButton: trls[TrlKeys.resetButton],
            }}
            errorPlaceholder={
                <Placeholder title={trls[TrlKeys.errorTitle]} description={trls[TrlKeys.errorDescription]} />
            }
        ></Suggest>
    );
});

export default PositionField;
