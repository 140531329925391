import { Button, Text, VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoButton, { ButtonKind } from 'bloko/blocks/button';
import { H3 } from 'bloko/blocks/header';
import VSpacingBloko from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    similarTitle: 'employer.nomoreviews.similarResumeContactsService.apply',
    apply: 'Apply',
};

const BuyContactsResumeHasSim: TranslatedComponent = ({ trls }) => {
    const simVacancyId = useSelector(({ snippetAccessResume }) => snippetAccessResume?.sim?.vacancyId);
    const isMagritte = useMagritte();

    if (simVacancyId) {
        return isMagritte ? (
            <>
                <Text typography="title-5-semibold">{trls[TrlKeys.similarTitle]}</Text>
                <VSpacing default={16} />
                <Button
                    size="small"
                    style="accent"
                    mode="primary"
                    data-qa="show-contacts-resume-apply"
                    Element={Link}
                    to={`${paths.resumeSearch}/similar/apply?vacancy_id=${simVacancyId}`}
                >
                    {trls[TrlKeys.apply]}
                </Button>
            </>
        ) : (
            <>
                <H3>{trls[TrlKeys.similarTitle]}</H3>
                <VSpacingBloko base={4} />
                <BlokoButton
                    kind={ButtonKind.Primary}
                    data-qa="show-contacts-resume-apply"
                    Element={Link}
                    to={`${paths.resumeSearch}/similar/apply?vacancy_id=${simVacancyId}`}
                >
                    {trls[TrlKeys.apply]}
                </BlokoButton>
            </>
        );
    }

    return null;
};

export default translation(BuyContactsResumeHasSim);
