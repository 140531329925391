import { DefaultRootState } from 'react-redux';
import { Dispatch } from 'redux';

import { ResumeApi } from 'src/models/applicant/resume/api';
import { showNotification, toggleResumeEditorSaving } from 'src/models/applicant/resume/editor/store/slice';
import { Thunk } from 'src/models/applicant/resume/editor/types/actions';
import { selectApplicantResume, selectResumeHash } from 'src/models/applicant/resume/selectors';
import { setApplicantResumeFields } from 'src/models/applicant/resume/slice';

export const touchResume =
    (): Thunk =>
    async (dispatch: Dispatch, getState: () => DefaultRootState): Promise<void> => {
        const resume = selectApplicantResume(getState());
        const resumeHash = selectResumeHash(getState());

        try {
            dispatch(toggleResumeEditorSaving(true));

            await ResumeApi.touch(resumeHash);

            /**
             * так как бэк не возвращает данные,
             * то мы локально устанавливаем текущее время как updated.
             * при обновлении страници прилетит уже данные с бэка.
             * Отличия будут в секундах, что не критично.
             * */
            const updated = new Date().valueOf();

            dispatch(setApplicantResumeFields({ _attributes: { ...resume._attributes, updated } }));

            dispatch(showNotification('success-touch'));
            // mb sentry log
        } catch (_) {
            dispatch(showNotification('error-api'));
            // mb sentry log
        } finally {
            dispatch(toggleResumeEditorSaving(false));
        }
    };
