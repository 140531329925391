import { useState } from 'react';

import { VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ResumeChatPinnedMessages from 'src/components/ResumeView/ResumeChatPinnedMessages';
import WebcallButton from 'src/components/WebcallButton';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import ResumeContactsChatButton from 'src/pages/ResumeView/components/ResumeHeader/Contacts/ResumeContactsChat';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';
import { RESUME_BLOCK_VSPACING } from 'src/pages/ResumeView/employerRedesign/const';
import { PHONES } from 'src/utils/constants/resumeHiddenFields';
import { VIEW_WITHOUT_CONTACTS } from 'src/utils/constants/resumePermission';

import ResumeContactsEmail from 'src/pages/ResumeView/employerRedesign/components/ResumeContacts/ResumeContactsEmail';
import ResumeContactsPersonalSites from 'src/pages/ResumeView/employerRedesign/components/ResumeContacts/ResumeContactsPersonalSites';
import ResumeContactsPhones from 'src/pages/ResumeView/employerRedesign/components/ResumeContacts/ResumeContactsPhones';
import ShowContacts from 'src/pages/ResumeView/employerRedesign/components/ResumeContacts/ShowContacts';

const TrlKeys = {
    title: 'resume.field.contacts',
};

const ResumeContacts: TranslatedComponent = ({ trls }) => {
    const isAllowed = useSelector((state) => state.resume?.permission !== VIEW_WITHOUT_CONTACTS);
    const isPhoneHidden = useSelector((state) => state.resume?.hiddenFields.includes(PHONES));
    const openedContacts = useSelector((state) => state.resume?.openedContacts);
    const phone = useSelector((state) => state.resume?.phone.value?.[0]);

    const [contactsVisibility, setContactsVisibility] = useState(isPhoneHidden || !phone || openedContacts);
    const pinnedChatMessages = useSelector((state) => state.pinnedChatMessages?.items);

    if (!isAllowed) {
        return null;
    }

    return (
        <ResumeBlock
            title={trls[TrlKeys.title]}
            data-qa="resume-block-contacts"
            buttons={
                <>
                    <WebcallButton isResumeViewMagritte />
                    <ResumeContactsChatButton isResumeViewMagritte />
                </>
            }
        >
            {contactsVisibility ? (
                <VSpacingContainer default={RESUME_BLOCK_VSPACING}>
                    <ResumeContactsPhones />
                    <ResumeContactsEmail />
                    <ResumeContactsPersonalSites />
                </VSpacingContainer>
            ) : (
                phone && <ShowContacts phone={phone} setContactsVisibility={setContactsVisibility} />
            )}
            {pinnedChatMessages && (
                <>
                    <VSpacing default={4} />
                    <ResumeChatPinnedMessages pinnedChatMessages={pinnedChatMessages} />
                </>
            )}
        </ResumeBlock>
    );
};

export default translation(ResumeContacts);
