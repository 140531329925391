import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import VSpacing from 'bloko/blocks/vSpacing';
import { formatToReactComponent } from 'bloko/common/trl';

import InfoBlockSubstrate, { InfoBlockSubstrateKind } from 'src/components/InfoBlockSubstrate';
import translation from 'src/components/translation';
import { UserType } from 'src/models/userType';

const ResumeAnonymousWarning = ({ hide, trls, hiddenFields }) => {
    if (hide) {
        return null;
    }

    const lastIndex = hiddenFields.length - 1;

    return (
        <div className="noprint">
            <div className="info-block-flex">
                <InfoBlockSubstrate
                    kind={InfoBlockSubstrateKind.Warning}
                    data-qa="info-block-substrate-warning"
                    noTopIndent
                    slim
                >
                    <p data-qa="anonymous-resume-warning-text" data-hidden-fields={hiddenFields.join(', ')}>
                        {formatToReactComponent(trls[ResumeAnonymousWarning.trls.content], {
                            '{0}': hiddenFields
                                .map((field, index) => {
                                    let delimiter = '';
                                    if (index !== 0) {
                                        delimiter =
                                            index === lastIndex ? ` ${trls[ResumeAnonymousWarning.trls.and]} ` : ', ';
                                    }
                                    return delimiter + trls[ResumeAnonymousWarning.trls[field]];
                                })
                                .join(''),
                        })}
                    </p>
                    <p>{trls[ResumeAnonymousWarning.trls.buy]}</p>
                </InfoBlockSubstrate>
            </div>
            <VSpacing base={6} />
        </div>
    );
};

ResumeAnonymousWarning.trls = {
    content: 'resume.anonymous.warning.pre',
    buy: 'resume.anonymous.warning.variants',

    names_and_photo: 'resume.anonymous.warning.field.names_and_photo',
    phones: 'resume.anonymous.warning.field.phones',
    email: 'resume.anonymous.warning.field.email',

    other_contacts: 'resume.anonymous.warning.field.other_contacts',
    experience: 'resume.anonymous.warning.field.experience',
    and: 'abstract.and',
};

ResumeAnonymousWarning.propTypes = {
    hide: PropTypes.bool,
    trls: PropTypes.object,
    hiddenFields: PropTypes.arrayOf(PropTypes.string),
};

export default connect((state) => {
    return {
        hide: !(
            state.userType === UserType.Employer &&
            state.resume?.hiddenFields &&
            state.resume?.hiddenFields.length !== 0 &&
            state.resume?.resumeContactsOpening
        ),
        hiddenFields: state.resume?.hiddenFields,
    };
})(translation(ResumeAnonymousWarning));
