import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import isTomorrow from 'date-fns/isTomorrow';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Button, Card, Cell, CellText, VSpacing } from '@hh.ru/magritte-ui';
import { ArrowUpOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import { format } from 'bloko/common/trl';

import { sendAnalyticsFindJobFaster } from 'Modules/ApplicantAnalytics';
import formatDate from 'Modules/formatDate';
import { selectResumeEditorSaving } from 'src/models/applicant/resume/editor/store/selectors';
import { touchResume } from 'src/models/applicant/resume/renewal/actions';
import { useShowUpdateBlock } from 'src/models/applicant/resume/renewal/hooks';
import {
    selectIsRenewal,
    selectUpdatedTimeMs,
    selectUpdatedTimeoutMs,
    selectHasRenewalService,
    selectResumeRenewalServiceExpireTime,
} from 'src/models/applicant/resume/renewal/selectors';
import { selectResumeHash } from 'src/models/applicant/resume/selectors';

const TrlKeys = {
    title: 'applicant.resume.update.title',
    updateBtn: 'applicant.resume.update.button.text',
    lastUpdate: 'applicant.resume.update.last',
    renewalLink: 'applicant.resume.update.renewal.link',
    renewalToday: 'applicant.resume.update.manual.time.today',
    renewalTomorrow: 'applicant.resume.update.manual.time.tomorrow',
    renewalAutomatic: 'applicant.resume.update.renewal.automatic',
};

const RenewalResume = translation(({ trls }) => {
    const dispatch = useDispatch();

    const visibility = useShowUpdateBlock();

    const hash = useSelector(selectResumeHash);
    const renewal = useSelector(selectIsRenewal);
    const loading = useSelector(selectResumeEditorSaving);
    const updatedTimeMs = useSelector(selectUpdatedTimeMs);
    const updateTimeoutMs = useSelector(selectUpdatedTimeoutMs);
    const hasRenewalService = useSelector(selectHasRenewalService);
    const renewalServiceExpireTime = useSelector(selectResumeRenewalServiceExpireTime);

    const renewalServiceExpireDate = useMemo(() => {
        if (renewal) {
            return new Date(renewalServiceExpireTime);
        }
        return new Date();
    }, [renewal, renewalServiceExpireTime]);

    const isManualUpdateable = useMemo(() => {
        const msToManualUpdate = new Date(updatedTimeMs + updateTimeoutMs).getTime() - new Date().getTime();

        return msToManualUpdate <= 0;
    }, [updateTimeoutMs, updatedTimeMs]);

    const manualUpdateDateMs = useMemo(
        () => new Date(updatedTimeMs + updateTimeoutMs).getTime(),
        [updatedTimeMs, updateTimeoutMs]
    );

    const handleUpdate = () => {
        void dispatch(touchResume());
    };

    if (!visibility) {
        return null;
    }

    if (renewal) {
        return (
            <Card padding={12} borderRadius={24} showBorder>
                <Card padding={12}>
                    <Cell left={<ArrowUpOutlinedSize24 />}>
                        <CellText type="description">{trls[TrlKeys.title]}</CellText>
                        <CellText style="positive">
                            {format(trls[TrlKeys.renewalAutomatic], {
                                '{0}': formatDate(renewalServiceExpireDate, 'dd MMMM'),
                            })}
                        </CellText>
                    </Cell>
                </Card>
            </Card>
        );
    }

    if (isManualUpdateable) {
        return (
            <Card padding={12} borderRadius={24} showBorder>
                <Card padding={12}>
                    <Cell left={<ArrowUpOutlinedSize24 />}>
                        <CellText type="description">{trls[TrlKeys.title]}</CellText>
                        <CellText>
                            {format(trls[TrlKeys.lastUpdate], {
                                '{0}': formatDate(updatedTimeMs, 'dd MMMM'),
                                '{1}': formatDate(updatedTimeMs, 'HH:mm'),
                            })}
                        </CellText>
                    </Cell>
                </Card>
                <VSpacing default={12} />
                <Button
                    size="small"
                    mode="primary"
                    style="accent"
                    loading={loading}
                    disabled={loading}
                    onClick={handleUpdate}
                    data-qa="resume-update-button"
                    stretched
                >
                    {trls[TrlKeys.updateBtn]}
                </Button>
            </Card>
        );
    }
    return (
        <Card padding={12} borderRadius={24} showBorder>
            <Card padding={12}>
                <Cell left={<ArrowUpOutlinedSize24 />}>
                    <CellText type="description">{trls[TrlKeys.title]}</CellText>
                    <CellText>
                        {format(
                            isTomorrow(manualUpdateDateMs) ? trls[TrlKeys.renewalTomorrow] : trls[TrlKeys.renewalToday],
                            {
                                '{0}': formatDate(manualUpdateDateMs, 'HH:mm'),
                            }
                        )}
                    </CellText>
                </Cell>
            </Card>
            {hasRenewalService && (
                <>
                    <VSpacing default={12} />
                    <Button
                        Element={Link}
                        data-qa="resumeservice-button__renewresume"
                        stretched
                        to={`/applicant/services/payment?from=myresume&package=basic&resumeHash=${hash}`}
                        size="small"
                        mode="secondary"
                        style="accent"
                        onMouseUp={sendAnalyticsFindJobFaster}
                    >
                        {trls[TrlKeys.renewalLink]}
                    </Button>
                </>
            )}
        </Card>
    );
});

export default RenewalResume;
