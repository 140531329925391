import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from '@hh.ru/front-static-app';

import { Phone, PreferredContact } from 'src/models/applicant/resume/blocks/contacts/types';
import { useResumeEditorField } from 'src/models/applicant/resume/editor/form/change';
import { ErrorsMap } from 'src/models/applicant/resume/editor/form/errors';
import { makeErrorMessage } from 'src/models/applicant/resume/editor/form/errors/lib';
import {
    selectResumeConditionByKey,
    selectResumeEditorErrors,
} from 'src/models/applicant/resume/editor/store/selectors';
import {
    setResumeEditorFieldValue,
    clearResumeEditorErrorByNamePath,
} from 'src/models/applicant/resume/editor/store/slice';

import {
    selectResumeEditorEmail,
    selectResumeEditorPhone,
} from 'src/models/applicant/resume/blocks/contacts/editor/selectors';

interface FieldData {
    value: string;
    invalid: boolean;
    onChange: (value: string, resetError?: boolean) => void;
    errorMessage: string | null | undefined;
}

export const useResumeContactEditor = (
    key: 'personalSite' | 'phone',
    index: number,
    subname: string,
    errorsMap: ErrorsMap
): FieldData => {
    const dispatch = useDispatch();

    const formErrors = useSelector(selectResumeEditorErrors);
    const namePath = `/${key}/${index}/${subname}`;

    const condition = useSelector(selectResumeConditionByKey(key));
    const fields = useSelector(({ resumeEditor }) => resumeEditor.fields[key]);
    const field = fields?.[index];
    const fieldFormErrors = formErrors?.[key] || [];

    const errors = makeErrorMessage(fieldFormErrors, errorsMap, condition);
    const error = errors.find(({ fieldNamePath }) => fieldNamePath === namePath);

    const onChange = (value: string, resetError = true) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(
            setResumeEditorFieldValue({
                [key]: fields?.map((field, idx) => {
                    if (idx === index) {
                        return {
                            ...field,
                            [subname]: value,
                        };
                    }

                    return field;
                }),
            })
        );
        if (resetError) {
            dispatch(clearResumeEditorErrorByNamePath({ key, namePath }));
        }
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const value = field?.[subname] as string;

    return {
        value,
        onChange,
        invalid: !!error,
        errorMessage: error?.message,
    };
};

const PREFERRED_CONTACT__NAME = 'preferredContact';

export const usePreferredContactChanged = (): void => {
    // если мы очистили поля, которые выбранно как предпочитаемый способ связи
    // то нужно поменять значение preferredContact на заполненый
    const email = useSelector(selectResumeEditorEmail);
    const phone = useSelector(selectResumeEditorPhone);
    const { value: preferredContact, onChange } = useResumeEditorField(PREFERRED_CONTACT__NAME);

    useEffect(() => {
        const states = phone.map((p) => ({ value: p.formatted || '', type: `${p.type}_phone` as PreferredContact }));
        states.push({
            value: email,
            type: 'email' as PreferredContact,
        });

        const current = states.find((state) => state.type === preferredContact);
        if (current && current.value === '') {
            const valid = states.find((state) => state.value !== '');
            onChange(valid?.type ?? PreferredContact.CellPhone);
        }
    }, [phone, email, preferredContact, onChange]);
};

export const usePhoneChangedByIndex = (
    index: number,
    errorsMap: ErrorsMap
): {
    value: Phone;
    invalid: boolean;
    onChange: (value: Phone) => void;
    resetError: () => void;
    errorMessage: string | undefined;
} => {
    const dispatch = useDispatch();

    const phones = useSelector(selectResumeEditorPhone);
    const formErrors = useSelector(selectResumeEditorErrors);
    const condition = useSelector(selectResumeConditionByKey('phone'));
    const phone = phones[index];

    const namePath = `/phone/${index}`;
    const fieldFormErrors = formErrors?.phone || [];

    const errors = makeErrorMessage(fieldFormErrors, errorsMap, condition);
    const error = errors.find(({ fieldNamePath }) => fieldNamePath === namePath);

    const onChange = (value: Phone) => {
        dispatch(
            setResumeEditorFieldValue({
                phone: phones?.map((field, idx) => {
                    if (idx === index) {
                        return {
                            ...value,
                            // сохраняем старое значние коммент, так как он меняется отдельным хендлером
                            comment: field.comment,
                        };
                    }

                    return field;
                }),
            })
        );

        dispatch(clearResumeEditorErrorByNamePath({ key: 'phone', namePath }));
        dispatch(clearResumeEditorErrorByNamePath({ key: 'phone', namePath: `${namePath}/formatted` }));
    };

    const resetError = () => {
        dispatch(clearResumeEditorErrorByNamePath({ key: 'phone', namePath }));
    };

    return { value: phone, resetError, onChange, invalid: !!error, errorMessage: error?.message };
};
