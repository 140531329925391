import { useMemo, memo } from 'react';

import { Text, VSpacing } from '@hh.ru/magritte-ui';
import { GlobeOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import {
    FacebookSolidSize16,
    FlSolidSize16,
    IcqSolidSize16,
    LinkedinSolidSize16,
    LivejournalSolidSize16,
    MediumSolidSize16,
    SkypeSolidSize16,
} from '@hh.ru/magritte-ui/service';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import TagWithContrastText from 'src/components/TagWithContrastText';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import ResumeTagsContainer from 'src/pages/ResumeView/employerRedesign/components/ResumeTagsContainer';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const PERSONAL_TYPE = 'personal';

const ICONS = {
    personal: GlobeOutlinedSize16,
    linkedin: LinkedinSolidSize16,
    facebook: FacebookSolidSize16,
    icq: IcqSolidSize16,
    skype: SkypeSolidSize16,
    freelance: FlSolidSize16,
    livejournal: LivejournalSolidSize16,
    moi_krug: MediumSolidSize16,
};

const TrlKeys = {
    submitted: 'rb.personalsites.submitted',
    icq: 'rb.personalsites.icq',
    skype: 'rb.personalsites.skype',
    facebook: 'rb.personalsites.facebook',
    moi_krug: 'rb.personalsites.moi_krug',
    freelance: 'rb.personalsites.freelance',
    livejournal: 'rb.personalsites.livejournal',
    linkedin: 'rb.personalsites.linkedin',
    personal: 'rb.personalsites.personal',
};

const SocialMediaLinks: TranslatedComponent = ({ trls }) => {
    const personalSites = useSelector((state) => state.resume?.personalSite?.value || []);
    const personalSite = personalSites?.find(({ type, url }) => type === PERSONAL_TYPE && !!url);

    const socialSites = useMemo(() => {
        const sites = personalSites.filter(({ type }) => type !== PERSONAL_TYPE);
        const withUrl = sites.filter(({ url }) => !!url);
        const withoutUrl = sites.filter(({ url }) => !url);

        return [...withUrl, ...withoutUrl];
    }, [personalSites]);

    if (!(personalSite?.text || socialSites?.length)) {
        return null;
    }

    return (
        <div>
            <Text style="secondary" typography="label-3-regular">
                {trls[TrlKeys.submitted]}
            </Text>
            <VSpacing default={4} />
            <ResumeTagsContainer>
                {personalSite?.text && (
                    <a
                        href={personalSite.url}
                        rel="noopener noreferrer"
                        target="_blank"
                        data-qa={`resume-personalsite-${personalSite.type}`}
                    >
                        <TagWithContrastText icon={ICONS.personal}>
                            {personalSite.text.replace(/https?:\/\//, '')}
                        </TagWithContrastText>
                    </a>
                )}
                {socialSites?.length &&
                    socialSites.map(({ type, text, url }) => {
                        const contentText = text || trls[TrlKeys[type]];
                        return url ? (
                            <a key={type} href={url} rel="noopener noreferrer" target="_blank">
                                <TagWithContrastText icon={ICONS[type]}>{contentText}</TagWithContrastText>
                            </a>
                        ) : (
                            <TagWithContrastText key={type} icon={ICONS[type]}>
                                {`${trls[TrlKeys[type]]}:${NON_BREAKING_SPACE}${contentText}`}
                            </TagWithContrastText>
                        );
                    })}
            </ResumeTagsContainer>
        </div>
    );
};

export default memo(translation(SocialMediaLinks));
