import { PropsWithChildren, ReactNode } from 'react';

import { translation, useSelector } from '@hh.ru/front-static-app';
import {
    Modal,
    Button,
    Action,
    ActionBar,
    BottomSheet,
    useBreakpoint,
    NavigationBar,
    BottomSheetFooter,
    Loader,
    NavigationBarSize,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui-icon/icon';

import {
    selectResumeEditorLoading,
    selectResumeEditorSaving,
} from 'src/models/applicant/resume/editor/store/selectors';

import styles from './styles.less';

interface Props {
    title: string;
    description?: string;
    size?: NavigationBarSize;
    visible: boolean;
    showDivider?: false | 'with-scroll';
    onSave: () => void;
    onClose: () => void;
    onDelete?: () => void;
    disableSave?: boolean;
    extraSecondaryAction?: ReactNode;
}
const TrlKeys = {
    save: 'resume.editor.save',
    cancel: 'resume.editor.cancel',
    delete: 'resume.editor.delete',
};

const EditorModal = translation<PropsWithChildren<Props>>(
    ({
        children,
        trls,
        title,
        visible,
        onClose,
        description,
        onSave,
        onDelete,
        disableSave = false,
        extraSecondaryAction,
        size = 'large',
        showDivider = 'with-scroll',
    }) => {
        const saving = useSelector(selectResumeEditorSaving);
        const loading = useSelector(selectResumeEditorLoading);
        const loadingBtns = saving || loading;
        const { isMobile } = useBreakpoint();

        if (isMobile) {
            return (
                <BottomSheet
                    header={
                        <NavigationBar
                            title={title}
                            right={<Action icon={CrossOutlinedSize24} onClick={onClose} />}
                            size={size}
                            subtitle={description}
                            showDivider={showDivider}
                        />
                    }
                    visible={visible}
                    onClose={onClose}
                    showDivider={showDivider}
                    footer={
                        <BottomSheetFooter>
                            <Button
                                disabled={disableSave}
                                mode="primary"
                                style="accent"
                                size="medium"
                                onClick={onSave}
                                loading={loadingBtns}
                                stretched
                                data-qa="resume-modal-button-save"
                            >
                                {trls[TrlKeys.save]}
                            </Button>
                            {onDelete ? (
                                <Button
                                    mode="secondary"
                                    style="negative"
                                    size="medium"
                                    onClick={onDelete}
                                    stretched={false}
                                    loading={loadingBtns}
                                    data-qa="profile-modal-button-delete"
                                >
                                    {trls[TrlKeys.delete]}
                                </Button>
                            ) : undefined}
                        </BottomSheetFooter>
                    }
                >
                    {loading ? (
                        <div className={styles.loader}>
                            <Loader size={64} />
                        </div>
                    ) : (
                        children
                    )}
                </BottomSheet>
            );
        }
        return (
            <Modal
                size="medium"
                title={title}
                visible={visible}
                onClose={onClose}
                titleSize="large"
                titleMaxLines={2}
                titleDescriptionStyle="secondary"
                titleDescription={description}
                closeByClickOutside={false}
                actions={<Action style={'neutral'} mode={'secondary'} icon={CrossOutlinedSize24} onClick={onClose} />}
                footer={
                    <ActionBar
                        primaryActions={
                            <Button
                                mode="primary"
                                style="accent"
                                size="medium"
                                stretched={false}
                                onClick={onSave}
                                loading={loadingBtns}
                                disabled={disableSave}
                            >
                                {trls[TrlKeys.save]}
                            </Button>
                        }
                        secondaryActions={
                            <>
                                {extraSecondaryAction}
                                {onDelete ? (
                                    <Button
                                        mode="secondary"
                                        style="negative"
                                        size="medium"
                                        onClick={onDelete}
                                        stretched={false}
                                        loading={loadingBtns}
                                    >
                                        {trls[TrlKeys.delete]}
                                    </Button>
                                ) : undefined}
                            </>
                        }
                    />
                }
            >
                {loading ? (
                    <div className={styles.loader}>
                        <Loader size={64} />
                    </div>
                ) : (
                    children
                )}
            </Modal>
        );
    }
);

export default EditorModal;
