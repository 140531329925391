import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Alert, Button } from '@hh.ru/magritte-ui';
import { InfoCircleOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { submitResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';
import { closeResumeEditor } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import { selectResumeEditorChangedConfirm } from 'src/models/applicant/resume/editor/store/confirm/selectors';
import { hideResumeEditorConfirm } from 'src/models/applicant/resume/editor/store/slice';

const TrlKeys = {
    question: 'resume.editor.question',
    cancel: 'resume.editor.question.cancel',
    approve: 'resume.editor.question.approve',
};

const EditorCloseConfirm = translation(({ trls }) => {
    const dispatch = useDispatch();
    const visible = useSelector(selectResumeEditorChangedConfirm);

    const handleCancel = () => {
        dispatch(hideResumeEditorConfirm());
        dispatch(closeResumeEditor());
    };

    const handleApprove = () => {
        dispatch(hideResumeEditorConfirm());
        dispatch(submitResumeEditorData());
    };

    const handleClose = () => dispatch(hideResumeEditorConfirm());

    return (
        <Alert
            title={trls[TrlKeys.question]}
            visible={visible}
            onClose={handleClose}
            layout="vertical"
            iconStyle="neutral"
            icon={<InfoCircleOutlinedSize24 />}
            buttons={
                <>
                    <Button stretched mode="primary" style="accent" onClick={handleApprove}>
                        {trls[TrlKeys.approve]}
                    </Button>
                    <Button stretched mode="secondary" style="accent" onClick={handleCancel}>
                        {trls[TrlKeys.cancel]}
                    </Button>
                </>
            }
        />
    );
});

export default EditorCloseConfirm;
