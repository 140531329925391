import { useRef } from 'react';

import { Button, TooltipHover } from '@hh.ru/magritte-ui';
import { PrinterOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import translation from 'src/components/translation';
import { printResume } from 'src/models/applicant/resume/print';

const TrlKeys = {
    print: 'applicant.resume.print',
};

const ResumeButtonPrint = translation(({ trls }) => {
    const activatorRef = useRef<HTMLButtonElement>(null);
    const handlePrint = () => printResume();

    return (
        <>
            <Button
                ref={activatorRef}
                icon={<PrinterOutlinedSize16 />}
                mode="secondary"
                size="small"
                style="neutral"
                onClick={handlePrint}
                data-qa="resume-print-view"
            />
            <TooltipHover placement="top-center" activatorRef={activatorRef}>
                {trls[TrlKeys.print]}
            </TooltipHover>
        </>
    );
});

export default ResumeButtonPrint;
