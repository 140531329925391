import { useMemo } from 'react';

import { Cell, CellText, VSpacingContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';
import { RESUME_BLOCK_VSPACING } from 'src/pages/ResumeView/employerRedesign/const';

const TrlKeys = {
    title: 'resume.block.certificates.title',
};

const ResumeCertificates: TranslatedComponent = ({ trls }) => {
    const certificates = useSelector((state) => state.resume?.certificates.value);
    const sortedCertificates = useMemo(() => {
        return certificates?.data && Object.values(certificates.data).sort((a, b) => b.year - a.year);
    }, [certificates?.data]);

    if (!sortedCertificates?.length) {
        return null;
    }

    return (
        <ResumeBlock title={trls[TrlKeys.title]} data-qa="resume-block-certificate">
            <VSpacingContainer default={RESUME_BLOCK_VSPACING}>
                {sortedCertificates.map(({ id, url, title, year }) => {
                    return (
                        <Cell
                            key={id}
                            {...(url && {
                                Element: 'a',
                                href: url,
                                target: '_blank',
                                rel: 'noopener noreferrer',
                            })}
                        >
                            <CellText type="title">
                                <AsyncHighlighter>{title}</AsyncHighlighter>
                            </CellText>
                            <CellText type="subtitle">{year}</CellText>
                        </Cell>
                    );
                })}
            </VSpacingContainer>
        </ResumeBlock>
    );
};

export default translation(ResumeCertificates);
