import { useSelector } from '@hh.ru/front-static-app';
import { VSpacingContainer, Cell, CellText } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import translation from 'src/components/translation';
import { EducationLevel } from 'src/models/resume/resumeCommon.types';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';
import { RESUME_BLOCK_VSPACING } from 'src/pages/ResumeView/employerRedesign/const';

import ResumeEducationItem from 'src/pages/ResumeView/employerRedesign/components/ResumeEducation/ResumeEducationItem';

const TrlKeys = {
    title: 'resume.block.education.title',
    educationLevelTitle: 'resume.block.education.level',
    educationLevel: {
        [EducationLevel.Secondary]: 'resume.block.education.level.secondary',
        [EducationLevel.SpecialSecondary]: 'resume.block.education.level.special_secondary',
        [EducationLevel.UnfinishedHigher]: 'resume.block.education.level.unfinished_higher',
        [EducationLevel.Higher]: 'resume.block.education.level.higher',
        [EducationLevel.Bachelor]: 'resume.block.education.level.bachelor',
        [EducationLevel.Master]: 'resume.block.education.level.master',
        [EducationLevel.Candidate]: 'resume.block.education.level.candidate',
        [EducationLevel.Doctor]: 'resume.block.education.level.doctor',
    },
};

const ResumeEducation: TranslatedComponent = ({ trls }) => {
    const educationLevel = useSelector((state) => state.resume?.educationLevel.value);
    const elementaryEducation = useSelector((state) => state.resume?.elementaryEducation.value);
    const primaryEducation = useSelector((state) => state.resume?.primaryEducation.value);

    if (!educationLevel) {
        return null;
    }

    return (
        <ResumeBlock title={trls[TrlKeys.title]}>
            <VSpacingContainer default={RESUME_BLOCK_VSPACING}>
                {educationLevel && educationLevel !== EducationLevel.None && (
                    <Cell>
                        <CellText type="subtitle">{trls[TrlKeys.educationLevelTitle]}</CellText>
                        <CellText type="title">{trls[TrlKeys.educationLevel[educationLevel]]}</CellText>
                    </Cell>
                )}

                {educationLevel === EducationLevel.Secondary
                    ? elementaryEducation?.map(({ id, name, year }) => (
                          <ResumeEducationItem key={id} name={name} year={year} />
                      ))
                    : primaryEducation?.map(({ id, name, year, organization, result, universityId }) => (
                          <ResumeEducationItem
                              key={id}
                              name={name}
                              year={year}
                              organization={organization}
                              result={result}
                              link={universityId ? `${paths.resumeSearch}?university=${universityId}` : undefined}
                          />
                      ))}
            </VSpacingContainer>
        </ResumeBlock>
    );
};

export default translation(ResumeEducation);
