import { Banner, Text, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

const TrlKeys = {
    content: 'resume.anonymous.warning.pre',
    buy: 'resume.anonymous.warning.variants',

    names_and_photo: 'resume.anonymous.warning.field.names_and_photo',
    phones: 'resume.anonymous.warning.field.phones',
    email: 'resume.anonymous.warning.field.email',

    other_contacts: 'resume.anonymous.warning.field.other_contacts',
    experience: 'resume.anonymous.warning.field.experience',
    and: 'abstract.and',
};

const ResumeHasHiddenFieldsWarning: TranslatedComponent = ({ trls }) => {
    const hide = useSelector(
        (state) =>
            !(
                state.userType === UserType.Employer &&
                state.resume?.hiddenFields &&
                state.resume?.hiddenFields.length !== 0 &&
                state.resume?.resumeContactsOpening
            )
    );
    const hiddenFields = useSelector((state) => state.resume?.hiddenFields || []);

    if (hide) {
        return null;
    }

    const lastIndex = hiddenFields.length - 1;

    return (
        <>
            <Banner
                data-qa="info-block-substrate-warning"
                stretched
                style="warning"
                showClose={false}
                content={
                    <>
                        <Text data-qa="anonymous-resume-warning-text" data-hidden-fields={hiddenFields.join(', ')}>
                            {formatToReactComponent(trls[TrlKeys.content], {
                                '{0}': hiddenFields
                                    .map((field, index) => {
                                        let delimiter = '';
                                        if (index !== 0) {
                                            delimiter = index === lastIndex ? ` ${trls[TrlKeys.and]} ` : ', ';
                                        }
                                        return delimiter + trls[TrlKeys[field as keyof typeof TrlKeys]];
                                    })
                                    .join(''),
                            })}
                        </Text>
                        <Text>{trls[TrlKeys.buy]}</Text>
                    </>
                }
            />
            <VSpacing default={24} />
        </>
    );
};

export default translation(ResumeHasHiddenFieldsWarning);
