import { useDispatch } from 'react-redux';

import { useSelector, translation } from '@hh.ru/front-static-app';

import { getStringListTrl } from 'src/models/applicant/resume/blocks/additional/lib';
import { selectResumeEmployment } from 'src/models/applicant/resume/blocks/additional/selectors';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import Cell from 'src/pages/ResumeView/redesign/blocks/additional/Card/Cell';

const TrlKeys = {
    title: 'resume.editor.employment.title',
    more: 'resume.editor.additional.more',
    type: {
        full: 'resume.editor.employment.full',
        part: 'resume.editor.employment.part',
        project: 'resume.editor.employment.project',
        volunteer: 'resume.editor.employment.volunteer',
        probation: 'resume.editor.employment.probation',
    },
};

const Employment = translation(({ trls }) => {
    const dispatch = useDispatch();
    const employment = useSelector(selectResumeEmployment);

    const handleEdit = () => dispatch(openResumeEditorModal('employment'));
    return (
        <Cell
            title={trls[TrlKeys.title]}
            text={getStringListTrl(employment, trls, TrlKeys.type, trls[TrlKeys.more])}
            onClick={handleEdit}
        />
    );
});

export default Employment;
