import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import { Button } from '@hh.ru/magritte-ui-button/Button';
import { CheckCircleOutlinedSize16, CheckOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import BlokoButton, { ButtonKind, ButtonType } from 'bloko/blocks/button';
import MenuItem from 'bloko/blocks/drop/Menu/Item';
import Loading, { LoadingColor, LoadingScale } from 'bloko/blocks/loading';
import urlParser from 'bloko/common/urlParser';

import openContacts from 'Modules/Resume/Contacts/openContacts.obfuscate';
import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import { useNotification } from 'src/components/Notifications/Provider';
import mapErrorKeyToNotification from 'src/components/ResumeView/mapErrorKeyToNotification';
import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import DivisionSelector from 'src/pages/ResumeView/components/ResumeHeader/Contacts/DivisionSelector';

const defaultErrorNotification = mapErrorKeyToNotification.resourceUnavailable;

const OpenContacts = ({ trls, type, onContactsClick }) => {
    const { hash: resumeHash, id: resumeId, phonePreview } = useSelector(({ resume }) => resume);
    const currentDivisionId = useSelector((state) => state.employerCurrentDivision?.divisionId);
    const [divisionId, setDivisionId] = useState(currentDivisionId);
    const vacancyId = useSelector(({ router }) => router.location.query?.vacancyId);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const { addNotification } = useNotification();
    const hasVerifiedPhone = phonePreview?.some((phone) => phone.verified);
    const isMagritte = useMagritte();
    const openAndMarkContacts = useCallback(
        (event) => {
            setButtonDisabled(true);
            openContacts({ divisionId, resumeHash, resumeId, event, vacancyId })
                .then(({ data }) => {
                    if (data?.errorKey) {
                        addNotification(mapErrorKeyToNotification[data.errorKey] || defaultErrorNotification);
                        setButtonDisabled(false);
                        return;
                    }

                    onContactsClick?.('show_contacts');

                    Analytics.sendHHEvent('resumeShowContacts', {
                        goal: 'OpenContacts',
                        resumeId,
                        vacancyId,
                    });

                    const url = urlParser(window.location);
                    delete url.params.error;
                    // Списался последний контакт, на перезагруженной странице будет уведомление
                    if (data?.contactsLeft === 0) {
                        url.params.empty = true;
                    }
                    // Просто чтобы отличать в логах страницы со свежеоткрытыми контактами
                    if (data?.contactsOpened) {
                        url.params.contactsOpened = data.contactsOpened;
                    }

                    window.location.assign(url.href);
                })
                .catch(() => {
                    setButtonDisabled(false);
                    addNotification(defaultErrorNotification);
                });
        },
        [addNotification, onContactsClick, divisionId, resumeHash, resumeId, vacancyId]
    );

    if (type === 'menuItem') {
        return isMagritte ? (
            <Button mode="tertiary" style="neutral" size="large" onClick={openAndMarkContacts}>
                {trls[OpenContacts.trls.content]}
            </Button>
        ) : (
            <MenuItem onClick={openAndMarkContacts}>{trls[OpenContacts.trls.content]}</MenuItem>
        );
    }

    return (
        <div className="resume__contacts">
            {isMagritte ? (
                <>
                    <div>
                        {hasVerifiedPhone && (
                            <div className="resume-contacts-verified-phone">
                                <CheckOutlinedSize16 initial="positive" />
                                {NON_BREAKING_SPACE}
                                {trls[OpenContacts.trls.phoneVerified]}
                            </div>
                        )}
                        <Button
                            mode="primary"
                            style="accent"
                            size="small"
                            onClick={openAndMarkContacts}
                            loading={buttonDisabled}
                            disabled={buttonDisabled}
                            data-qa="buy-contacts"
                        >
                            {trls[OpenContacts.trls.content]}
                        </Button>
                    </div>
                    {/* todo: перевести DivisionSelector на магрит */}
                    {divisionId && <DivisionSelector currentDivisionId={divisionId} setDivisionId={setDivisionId} />}
                </>
            ) : (
                <>
                    <div>
                        {hasVerifiedPhone && (
                            <div className="resume-contacts-verified-phone">
                                <BlokoIconReplaceContainer>
                                    <CheckCircleOutlinedSize16 initial="positive" />
                                </BlokoIconReplaceContainer>
                                {NON_BREAKING_SPACE}
                                {trls[OpenContacts.trls.phoneVerified]}
                            </div>
                        )}
                        <BlokoButton
                            type={ButtonType.Button}
                            onClick={openAndMarkContacts}
                            kind={ButtonKind.Primary}
                            data-qa="buy-contacts"
                            disabled={buttonDisabled}
                            loading={
                                buttonDisabled && <Loading initial={LoadingColor.White} scale={LoadingScale.Small} />
                            }
                        >
                            {trls[OpenContacts.trls.content]}
                        </BlokoButton>
                    </div>
                    {divisionId && <DivisionSelector currentDivisionId={divisionId} setDivisionId={setDivisionId} />}
                </>
            )}
        </div>
    );
};

OpenContacts.trls = {
    content: 'employer.resume.showContacts',
    phoneVerified: 'resume.phoneVerified',
};

OpenContacts.propTypes = {
    type: PropTypes.string.isRequired,
    trls: PropTypes.object,
    onContactsClick: PropTypes.func,
};

export default translation(OpenContacts);
