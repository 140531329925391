import { useMemo } from 'react';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Button, Text } from '@hh.ru/magritte-ui';

import { getItemsToAdd } from 'src/models/applicant/resume/blocks/contacts/lib';
import { selectResumePhoneType } from 'src/models/applicant/resume/blocks/contacts/selectors';
import { Phone } from 'src/models/applicant/resume/blocks/contacts/types';
import { useResumeEditorField } from 'src/models/applicant/resume/editor/form/change';
import ButtonsWrapper from 'src/pages/ResumeView/redesign/blocks/contacts/Editor/ButtonsWrapper';
import { FIELD_NAME } from 'src/pages/ResumeView/redesign/blocks/contacts/Editor/Phone/const';

const TrlKeys = {
    add: 'resume.editor.contacts.add',
    phone: {
        home: 'applicant.resume.contacts.field.phone.home',
        work: 'applicant.resume.contacts.field.phone.work',
        cell: 'applicant.resume.contacts.field.phone.cell',
    },
};

const PhoneAdd = translation(({ trls }) => {
    const types = useSelector(selectResumePhoneType);

    const { value: phone, onChange } = useResumeEditorField(FIELD_NAME);

    const handleAddPhone = (newSite: Phone) => {
        onChange([...phone, newSite]);
    };

    const additionTypes = useMemo(() => getItemsToAdd(types, phone), [types, phone]);

    if (additionTypes.length === 0) {
        return null;
    }

    return (
        <ButtonsWrapper>
            <Text typography="label-3-regular">{trls[TrlKeys.add]}</Text>
            {additionTypes.map((type) => (
                <Button
                    data-qa={`resume-phone-set-${type}`}
                    key={type}
                    size="small"
                    style="neutral"
                    mode="secondary"
                    onClick={() => handleAddPhone({ type, formatted: '' })}
                >
                    {trls[TrlKeys.phone[type]]}
                </Button>
            ))}
        </ButtonsWrapper>
    );
});

export default PhoneAdd;
