import { FocusEvent, useMemo } from 'react';

import { translation } from '@hh.ru/front-static-app';
import { InputSize, Select } from '@hh.ru/magritte-ui';

import { capitalizeAll } from 'src/models/applicant/resume/lib/common/string';

interface Props {
    value: string;
    size?: InputSize;
    invalid: boolean;
    onBlur?: (e?: FocusEvent<HTMLElement>) => void;
    onFocus?: VoidFunction;
    onChange: (value: string) => void;
    elevateLabel?: boolean;
    widthEqualToActivator?: boolean;
}

type Month =
    | 'month1'
    | 'month2'
    | 'month3'
    | 'month4'
    | 'month5'
    | 'month6'
    | 'month7'
    | 'month8'
    | 'month9'
    | 'month10'
    | 'month11'
    | 'month12';

const TrlKeys = {
    monthPlaceholder: 'resume.editor.placeholder.month',
    month1: 'month.1',
    month2: 'month.2',
    month3: 'month.3',
    month4: 'month.4',
    month5: 'month.5',
    month6: 'month.6',
    month7: 'month.7',
    month8: 'month.8',
    month9: 'month.9',
    month10: 'month.10',
    month11: 'month.11',
    month12: 'month.12',
};

const MonthInput = translation<Props>(
    ({ trls, value, size = 'large', invalid, elevateLabel, widthEqualToActivator, onFocus, onBlur, onChange }) => {
        const options = useMemo(() => {
            return [...Array(12).keys()].map((index) => ({
                label: capitalizeAll(trls[TrlKeys[`month${index + 1}` as Month]]),
                value: (index + 1).toString().padStart(2, '0'),
            }));
        }, [trls]);

        return (
            <Select
                type="radio"
                searchable
                value={value}
                triggerProps={{
                    size,
                    invalid,
                    elevateLabel,
                    label: trls[TrlKeys.monthPlaceholder],
                    stretched: true,
                }}
                options={options}
                name="month-select"
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                widthEqualToActivator={widthEqualToActivator}
            />
        );
    }
);

export default MonthInput;
