export const NEW_ID = -1;

interface WithID {
    id: number;
}

export const isNewArrayField = ({ id }: WithID): boolean => {
    return id === NEW_ID;
};

export const updateItemById = <T extends WithID>(resumeRecommendation: T[], recommendation: T): T[] => {
    return resumeRecommendation.map((resumeRec) => {
        if (resumeRec.id === recommendation.id) {
            return recommendation;
        }
        return resumeRec;
    });
};

export const isChangedByKeys =
    <T extends object>(keys: (keyof T)[]) =>
    (a: T, b: T): boolean => {
        return keys.some((key) => {
            const valueA = a[key];
            const valueB = b[key];
            if (valueA === null) {
                return valueB !== '';
            }
            if (valueB === null) {
                return valueA !== '';
            }
            return a[key] !== b[key];
        });
    };

export const areSimpleArraysDifferent = (arr1: string[] | number[], arr2: string[] | number[]): boolean => {
    if (arr1.length !== arr2.length) {
        return true;
    }

    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();

    return !sortedArr1.every((value, index) => value === sortedArr2[index]);
};
