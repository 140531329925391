import { FC } from 'react';
import classNames from 'classnames';

import { Card, VSpacing } from '@hh.ru/magritte-ui';
import useBreakpoint from '@hh.ru/magritte-ui-breakpoint/useBreakpoint';

import { RESUME_BLOCK_VSPACING } from 'src/pages/ResumeView/employerRedesign/const';

import ResumeGenderAndAge from 'src/pages/ResumeView/employerRedesign/components/ResumeMainInfo/ResumeGenderAndAge';
import ResumeJobSearchStatus from 'src/pages/ResumeView/employerRedesign/components/ResumeMainInfo/ResumeJobSearchStatus';
import ResumeLastActivityTime from 'src/pages/ResumeView/employerRedesign/components/ResumeMainInfo/ResumeLastActivityTime';
import ResumeName from 'src/pages/ResumeView/employerRedesign/components/ResumeMainInfo/ResumeName';
import ResumePersonalLocation from 'src/pages/ResumeView/employerRedesign/components/ResumeMainInfo/ResumePersonalLocation';
import ResumePhoto from 'src/pages/ResumeView/employerRedesign/components/ResumeMainInfo/ResumePhoto';
import ResumeUpdateDate from 'src/pages/ResumeView/employerRedesign/components/ResumeMainInfo/ResumeUpdateDate';

import styles from './index.less';

const ResumeMainInfo: FC = () => {
    const { isMobile } = useBreakpoint();

    return (
        <Card stretched showBorder={!isMobile} borderRadius={24} padding={12}>
            <div
                className={classNames(styles.contentWrapper, {
                    [styles.contentWrapperMobile]: isMobile,
                })}
            >
                <ResumePhoto />
                <div
                    className={classNames(styles.text, {
                        [styles.textMobile]: isMobile,
                    })}
                >
                    <ResumeName />
                    <VSpacing default={8} />
                    <ResumeGenderAndAge />
                    <ResumePersonalLocation />
                    <VSpacing default={RESUME_BLOCK_VSPACING} />
                    <div
                        className={classNames(styles.tags, {
                            [styles.tagsMobile]: isMobile,
                        })}
                    >
                        <ResumeUpdateDate />
                        <ResumeLastActivityTime />
                        <ResumeJobSearchStatus />
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default ResumeMainInfo;
