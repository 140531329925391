import { Text } from '@hh.ru/magritte-ui';

import ConversionAge from 'src/components/Conversion/Age';
import HumanDate from 'src/components/HumanDate';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { Gender } from 'src/models/resume/resumeCommon.types';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    gender: {
        male: 'resumeConverter.gender.male',
        female: 'resumeConverter.gender.female',
        unknown: 'resumeConverter.gender.unknown',
    },
    born: {
        male: 'resumeConverter.born.male',
        female: 'resumeConverter.born.female',
        unknown: 'resumeConverter.born.unknown',
    },
};

const ResumeGenderAndAge = translation(({ trls }) => {
    const gender = useSelector((state) => state.resume?.gender.value || Gender.Unknown);
    const age = useSelector((state) => state.resume?.age.value);
    const birthday = useSelector((state) => state.resume?.birthday.value);

    const renderGender = () => {
        if (!gender) {
            return null;
        }
        return <span data-qa="resume-personal-gender">{trls[TrlKeys.gender[gender]]}</span>;
    };

    const renderAge = () => {
        if (!birthday || !age) {
            return null;
        }
        const birthdayTimezoneLeveling = `${birthday}T00:00:00`;
        return (
            <>
                {', '}
                <span data-qa="resume-personal-age">
                    <ConversionAge value={age} />
                </span>
                {', '}
                {trls[TrlKeys.born[gender]]}
                {NON_BREAKING_SPACE}
                <span data-qa="resume-personal-birthday">
                    <HumanDate date={birthdayTimezoneLeveling} />
                </span>
            </>
        );
    };

    return (
        <Text typography="paragraph-2-regular">
            {renderGender()}
            {renderAge()}
        </Text>
    );
});

export default ResumeGenderAndAge;
