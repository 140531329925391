import { ComponentType, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from '@hh.ru/front-static-app';
import {
    Button,
    Card,
    Cell,
    FormLabel,
    Text,
    useBreakpoint,
    useResize,
    VSpacing,
    VSpacingContainer,
} from '@hh.ru/magritte-ui';

import Markup from 'src/components/Markup';
import LINKIFY_EMAIL from 'src/components/Markup/LinkifyEmailProcessor';
import LINKIFY from 'src/components/Markup/LinkifyProcessor';
import NL2BR from 'src/components/Markup/Nl2BrProcessor';
import translation from 'src/components/translation';
import { selectApplicantResumeAbout } from 'src/models/applicant/resume/blocks/about/edit/selectors';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import ResumeAboutEditor from 'src/pages/ResumeView/redesign/blocks/about/Editor';
import EditButton from 'src/pages/ResumeView/redesign/common/EditButton';
import EditIcon from 'src/pages/ResumeView/redesign/common/EditIcon';

const TrlKeys = {
    title: 'resume.editor.about.title',
    edit: 'applicant.resume.about.edit.button',
    expand: {
        on: 'applicant.resume.about.expand.on',
        off: 'applicant.resume.about.expand.off',
    },
};

const MAX_LINES = 3;

enum ExpandStatus {
    Enable,
    Disable,
    NotCalculated,
}

const ApplicantResumeAbout: ComponentType = translation(({ trls }) => {
    const dispatch = useDispatch();
    const { isMobile } = useBreakpoint();
    const [expandStatus, setExpandStatus] = useState(ExpandStatus.NotCalculated);
    const [expanded, setExpanded] = useState(false);

    const value = useSelector(selectApplicantResumeAbout);

    useResize(() => {
        setExpandStatus(ExpandStatus.NotCalculated);
    });

    useEffect(() => {
        setExpandStatus(ExpandStatus.NotCalculated);
    }, [value]);

    const textContent = (
        <Text typography="label-2-regular" maxLines={expanded ? undefined : MAX_LINES} key={expandStatus}>
            <p
                ref={(instance) => {
                    if (!instance || expandStatus !== ExpandStatus.NotCalculated) {
                        return;
                    }
                    const currentHeight = instance.clientHeight;
                    const scrollHeight = instance.scrollHeight;

                    if (scrollHeight > currentHeight) {
                        setExpandStatus(ExpandStatus.Enable);
                    } else {
                        setExpandStatus(ExpandStatus.Disable);
                    }
                }}
            >
                <Markup sync={true} processors={[LINKIFY, LINKIFY_EMAIL, NL2BR]}>
                    {value}
                </Markup>
            </p>
        </Text>
    );

    const handleEdit = () => dispatch(openResumeEditorModal('about'));
    return (
        <>
            {value && (
                <VSpacingContainer default={12}>
                    <FormLabel>{trls[TrlKeys.title]}</FormLabel>
                    <Card stretched padding={12} borderRadius={24} showBorder>
                        <VSpacingContainer default={12}>
                            <Card padding={12} stretched>
                                {isMobile ? (
                                    <>{textContent}</>
                                ) : (
                                    <Cell align="top" right={<EditIcon onClick={handleEdit} />}>
                                        {textContent}
                                    </Cell>
                                )}
                            </Card>

                            <EditButton onClick={handleEdit}>{trls[TrlKeys.edit]}</EditButton>
                        </VSpacingContainer>

                        {!isMobile && expandStatus === ExpandStatus.Enable && (
                            <>
                                <VSpacing default={12} />
                                <Button
                                    stretched
                                    mode="secondary"
                                    style="neutral"
                                    size="medium"
                                    onClick={() => setExpanded((prevState) => !prevState)}
                                >
                                    {expanded ? trls[TrlKeys.expand.off] : trls[TrlKeys.expand.on]}
                                </Button>
                            </>
                        )}
                    </Card>
                </VSpacingContainer>
            )}
            <ResumeAboutEditor />
        </>
    );
});

export default ApplicantResumeAbout;
