import { FC } from 'react';

import { VSpacingContainer, Text } from '@hh.ru/magritte-ui';

import { Industry } from 'src/models/resume/resumeCommon.types';
import ResumeBullet from 'src/pages/ResumeView/employerRedesign/components/ResumeBullet';
import { RESUME_BLOCK_VSPACING } from 'src/pages/ResumeView/employerRedesign/const';

const ResumeExperienceIndustries: FC<{ industries: Industry[] }> = ({ industries }) => {
    if (!industries?.length) {
        return null;
    }

    return (
        <VSpacingContainer default={RESUME_BLOCK_VSPACING}>
            {industries
                .filter((industry) => industry.children.length > 0)
                .map((industry) => {
                    return (
                        <div key={industry.id}>
                            <Text typography="subtitle-2-semibold">{industry.title}</Text>
                            <ul>
                                {industry.children.map((subIndustry) => {
                                    return (
                                        <li key={subIndustry.id}>
                                            <ResumeBullet
                                                typography="paragraph-3-regular"
                                                leftSpace={false}
                                                paddings={false}
                                            />
                                            <Text Element="span" typography="paragraph-3-regular">
                                                {subIndustry.title}
                                            </Text>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    );
                })}
        </VSpacingContainer>
    );
};

export default ResumeExperienceIndustries;
