import { useMemo, useState, useCallback, useEffect } from 'react';

import Analytics from '@hh.ru/analytics-js';
import LocalStorageWrapper from 'bloko/common/storage/LocalStorageWrapper';
import support from 'bloko/common/supports';

import { StorageItem } from 'src/components/EmployerSearch/ContactFeedbackBanner/types';
import {
    handleStorageItem,
    getStorageItem,
    setStorageItem,
    getStorageKeyForResumeId,
} from 'src/components/EmployerSearch/ContactFeedbackBanner/utils/storageUtils';
import { useSelector } from 'src/hooks/useSelector';

export const useBannerProps = (
    resumeId: string,
    isExpAvailable?: boolean,
    appearanceDelay?: number,
    appearanceChance?: number,
    isPage?: boolean
): [boolean, number, ((triggerName: string) => void) | undefined] => {
    const hhid = useSelector(({ hhid }) => hhid || '');
    const [allowedToRenderBanner, storageResumeItem] = useMemo(
        () => handleStorageItem(!!isExpAvailable, resumeId, appearanceDelay, appearanceChance),
        [isExpAvailable, resumeId, appearanceDelay, appearanceChance]
    );
    const msTillBannerRender = useMemo(() => {
        if (!allowedToRenderBanner || !storageResumeItem?.triggerDate) {
            return 0;
        }

        const currentTimestamp = new Date().getTime();
        const timeDiff = currentTimestamp - storageResumeItem.triggerDate;

        return timeDiff > storageResumeItem.appearDelay ? 0 : storageResumeItem.appearDelay - timeDiff;
    }, [allowedToRenderBanner, storageResumeItem]);
    const wasBannerAlreadyTriggered = !!storageResumeItem?.triggerDate;
    const [shouldRenderBanner, setShouldRenderBanner] = useState(allowedToRenderBanner && wasBannerAlreadyTriggered);
    const [bannerAppearanceDelay, setBannerAppearanceDelay] = useState(msTillBannerRender);

    const isStorageCleared = LocalStorageWrapper.getItem('cfb_storage_cleared');
    if (!isStorageCleared && support.localStorage()) {
        // Фронтовый эксп с localStorage отключен, новые строчки добавляться не будут.
        // Чистим оставшиеся записи.
        for (const key in localStorage) {
            if (key.includes('cfb_')) {
                LocalStorageWrapper.removeItem(key);
            }
        }

        LocalStorageWrapper.setItem('cfb_storage_cleared', 'true');
    }

    const handleContactClick = useCallback(
        (triggerName: string) => {
            const storageKey = getStorageKeyForResumeId(resumeId);
            const storageItem = getStorageItem(storageKey);

            if (storageItem?.triggerDate || storageItem?.disableRender) {
                return;
            }

            const item = setStorageItem(
                storageKey,
                {
                    triggerDate: new Date().getTime(),
                    triggerName,
                },
                true
            );

            Analytics.sendHHEvent('resumeContactFeedbackBannerTriggered', {
                ...item,
                hhid,
                resumeId,
                hhtmSourceLabel: isPage ? 'resumePage' : 'resumeSnippet',
            });

            setShouldRenderBanner(true);
            setBannerAppearanceDelay(item.appearDelay);
        },
        [resumeId, hhid, isPage]
    );

    useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            const storageKey = getStorageKeyForResumeId(resumeId);

            if (event.key !== storageKey || !event.newValue) {
                return;
            }

            const newValue = JSON.parse(event.newValue) as StorageItem;
            if (newValue.disableRender) {
                setShouldRenderBanner(false);
            } else if (newValue.triggerDate && event.oldValue) {
                const oldValue = JSON.parse(event.oldValue) as StorageItem;

                if (!oldValue.triggerDate) {
                    setShouldRenderBanner(true);
                    setBannerAppearanceDelay(newValue.appearDelay);
                }
            }
        };

        if (allowedToRenderBanner) {
            window.addEventListener('storage', handleStorageChange);
        }

        return () => window.removeEventListener('storage', handleStorageChange);
    }, [allowedToRenderBanner, resumeId, setShouldRenderBanner, setBannerAppearanceDelay]);

    return [shouldRenderBanner, bannerAppearanceDelay, allowedToRenderBanner ? handleContactClick : undefined];
};
