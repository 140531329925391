import { ChangeEvent, useState } from 'react';

import { translation } from '@hh.ru/front-static-app';
import { TextArea, useBreakpoint } from '@hh.ru/magritte-ui';

import { useResumeEditorListField } from 'src/models/applicant/resume/editor/form/change';
import { ExperienceFieldProps } from 'src/pages/ResumeView/redesign/experience/Editor/types';

import DescriptionMobile from 'src/pages/ResumeView/redesign/experience/Editor/fields/Description/DescriptionMobile';

const TrlKeys = {
    titlePlaceholder: 'resume.editor.experience.description.title.placeholder',
    description: 'resume.editor.experience.description.description',
};

const DescriptionField = translation<ExperienceFieldProps>(({ trls, index, errorsMap, fieldName }) => {
    const [mobileVisible, setMobileVisible] = useState(false);

    const { value, onChange, invalid, errorMessage } = useResumeEditorListField(
        fieldName,
        index,
        'description',
        errorsMap
    );

    const { isMobile } = useBreakpoint();

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        onChange(event.target.value);
    };

    const handleFocus = () => {
        if (isMobile) {
            setMobileVisible(true);
        }
    };

    const formatDescriptionValue = () => {
        onChange(value.trim());
    };

    const handleCloseMobileDescription = () => {
        formatDescriptionValue();
        setMobileVisible(false);
    };

    return (
        <>
            <TextArea
                value={value}
                placeholder={trls[TrlKeys.titlePlaceholder]}
                onChange={handleChange}
                onFocus={handleFocus}
                invalid={invalid}
                layout="fill-both"
                errorMessage={errorMessage}
                onBlur={() => formatDescriptionValue()}
                description={trls[TrlKeys.description]}
            />
            <DescriptionMobile
                visible={mobileVisible}
                value={value}
                placeholder={trls[TrlKeys.titlePlaceholder]}
                invalid={invalid}
                onChange={handleChange}
                onClose={handleCloseMobileDescription}
            />
        </>
    );
});

export default DescriptionField;
