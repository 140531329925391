import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Link } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import ConversionNumber from 'bloko/blocks/conversion';
import Gap from 'bloko/blocks/gap';
import { H2 } from 'bloko/blocks/header';
import BlokoLink from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';
import urlParser from 'bloko/common/urlParser';

import paths from 'src/app/routePaths';
import translation from 'src/components/translation';
import { UserType } from 'src/models/userType';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';
import fetcher from 'src/utils/fetcher';

import Resume from 'src/pages/ResumeView/components/RelatedResumes/RelatedResume';

const MIN_RESUMES_AMOUNT_TO_RENDER_SHOW_ALL_LINK = 3;

function RelatedResumes({ hash, trls, userType }) {
    const [relatedResumes, setRelatedResumes] = useState(null);
    const { mMain } = useGetMColumnSize();

    useEffect(() => {
        if (userType === UserType.Applicant) {
            return;
        }

        fetcher
            .get('/related_resumes', {
                params: {
                    resume: hash,
                },
            })
            .then((response) => {
                setRelatedResumes(response);
            })
            .catch(() => {
                // ignore, as we load related resumes in background
            });
    }, [hash, userType]);

    if (!(relatedResumes && relatedResumes.resumes)) {
        return null;
    }

    const url = urlParser(paths.resumeSearch);
    url.params = {
        resume: [relatedResumes?.searchCriteria?.resume],
        search_period: [relatedResumes?.searchCriteria?.search_period],
        job_search_status: relatedResumes?.searchCriteria?.job_search_status,
    };

    return (
        <Column xs="4" s="8" m={mMain} l="12" data-qa="similar-resumes-block">
            <Gap top bottom>
                <hr className="resume-block__separator resume-block__separator_gap-top" />
            </Gap>
            <div className="resume-block resume-block_no-print">
                <H2 lite>
                    <span className="resume-block__title">
                        <span className="resume-block__title-text resume-block__title-text_sub">
                            {trls[RelatedResumes.trls.title]}
                        </span>
                    </span>
                </H2>
                <VSpacing base={4} />
                <ul>
                    {relatedResumes.resumes.map((resume) => (
                        <Resume resume={resume} key={resume._attributes.hash} />
                    ))}

                    {relatedResumes.totalUngroupedResults > MIN_RESUMES_AMOUNT_TO_RENDER_SHOW_ALL_LINK && (
                        <li>
                            <BlokoLink
                                disableVisited
                                Element={Link}
                                to={url.href}
                                data-qa="similar-resumes-showmore-link"
                            >
                                {trls[RelatedResumes.trls.showMore]}{' '}
                                <ConversionNumber
                                    one={trls[RelatedResumes.trls.one]}
                                    some={trls[RelatedResumes.trls.some]}
                                    many={trls[RelatedResumes.trls.many]}
                                    value={relatedResumes.totalUngroupedResults}
                                    hasValue
                                />
                            </BlokoLink>
                        </li>
                    )}
                </ul>
            </div>
        </Column>
    );
}

RelatedResumes.propTypes = {
    hash: PropTypes.string,
    trls: PropTypes.object,
    userType: PropTypes.string,
};

RelatedResumes.trls = {
    title: 'resume.related',
    showMore: 'resume.related.all',
    one: 'resume.related.1',
    some: 'resume.related.2',
    many: 'resume.related.5',
};

export default connect((state) => ({
    hash: state.resume?.hash,
    userType: state.userType,
}))(translation(RelatedResumes));
