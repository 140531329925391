import { cloneElement, ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import { SelectProps } from '@hh.ru/magritte-ui';

import { getMaxHeight } from 'src/utils/select/getMaxHeight';

interface Props<MultipleType extends boolean, T = unknown> {
    children: ReactElement<Partial<SelectProps<MultipleType, T>>>;
    onFocus?: () => void;
    multiple?: boolean;
}

const SelectFixedHeight = <MultipleType extends boolean, T = unknown>({
    children,
    onFocus,
    multiple,
}: Props<MultipleType, T>): ReactElement => {
    const [maxHeight, setMaxHeight] = useState(460);
    const selectRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setMaxHeight(getMaxHeight({ ref: selectRef, withApplyButton: multiple }));
    }, [multiple]);

    const onFocusCb = useCallback(() => {
        setMaxHeight(getMaxHeight({ ref: selectRef, withApplyButton: multiple }));
        onFocus?.();
    }, [multiple, onFocus]);

    return (
        <div ref={selectRef}>
            {cloneElement(children, {
                ...children.props,
                onFocus: onFocusCb,
                maxHeight,
            })}
        </div>
    );
};

export default SelectFixedHeight;
