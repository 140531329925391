import { FC, PropsWithChildren, useMemo } from 'react';

import { Avatar, Cell, CellText, Text, VSpacing, Link as MagritteLink } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import translation from 'src/components/translation';
import { useCdnImageHost } from 'src/hooks/useCdnImageHost';
import { useSelector } from 'src/hooks/useSelector';
import { EmployerState } from 'src/models/employerStatus';
import { CompanyLogoTypes, Experience } from 'src/models/resume/resumeCommon.types';
import ResumeBullet from 'src/pages/ResumeView/employerRedesign/components/ResumeBullet';
import ResumeExperienceInterval from 'src/pages/ResumeView/employerRedesign/components/ResumeExperience/ResumeExperienceInterval';
import { EXPERIENCE } from 'src/utils/constants/resumeHiddenFields';

import styles from './styles.less';

const TrlKeys = {
    isHidden: 'resume.hiddenFields.experience',
};

type ResumeExperienceInfoProps = Pick<
    Experience,
    | 'employerId'
    | 'companyName'
    | 'companyState'
    | 'companyAreaId'
    | 'companyAreaTitle'
    | 'companyUrl'
    | 'prettyUrl'
    | 'interval'
    | 'startDate'
    | 'endDate'
    | 'companyLogos'
>;

interface WrapperProps {
    url: string | false;
    logoSrc?: string | false;
    companyName: string;
}

const Wrapper: FC<PropsWithChildren<WrapperProps>> = ({ url, logoSrc, companyName, children }) => {
    const fullLogoSrc = useCdnImageHost(logoSrc || '');

    const logo = logoSrc ? (
        <Avatar
            mode="image"
            fallbackMode="placeholder"
            placeholder="city"
            size={48}
            shapeCircle
            image={fullLogoSrc}
            aria-label={companyName}
        />
    ) : (
        <Avatar mode="placeholder" placeholder="city" size={48} shapeCircle aria-label={companyName} />
    );

    if (url) {
        return (
            <Cell Element={Link} to={url} align="top" left={logo}>
                {children}
            </Cell>
        );
    }

    return (
        <Cell align="top" left={logo}>
            {children}
        </Cell>
    );
};

const ResumeExperienceInfo: TranslatedComponent<ResumeExperienceInfoProps> = ({
    employerId,
    companyName,
    companyState,
    companyAreaId,
    companyAreaTitle,
    companyUrl,
    prettyUrl,
    interval,
    startDate,
    endDate,
    companyLogos,
    trls,
}) => {
    const isHidden = useSelector((state) => state.resume?.hiddenFields.includes(EXPERIENCE));
    const isApproved = companyState === EmployerState.Approved;

    const companyLogoSrc = useMemo(() => {
        return (
            !isHidden &&
            companyLogos?.logo &&
            companyLogos.logo.length > 0 &&
            companyLogos.logo.find((logo) => logo.type === CompanyLogoTypes.Medium)?.url
        );
    }, [companyLogos?.logo, isHidden]);

    return (
        <Wrapper
            url={!isHidden && isApproved && `/employer/${employerId}`}
            logoSrc={companyLogoSrc}
            companyName={companyName}
        >
            <CellText type="title">
                {isHidden ? trls[TrlKeys.isHidden] : <AsyncHighlighter>{companyName}</AsyncHighlighter>}
            </CellText>
            {!isHidden && (companyAreaId || companyUrl) && (
                <Text typography="label-3-regular">
                    {companyAreaId && companyAreaTitle}
                    {companyUrl && (
                        <>
                            {companyAreaId && <ResumeBullet typography="paragraph-3-regular" />}
                            <MagritteLink
                                inline
                                style="neutral"
                                typography="label-3-regular"
                                href={companyUrl}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <span className={styles.link}>{prettyUrl}</span>
                            </MagritteLink>
                        </>
                    )}
                </Text>
            )}
            <VSpacing default={8} />
            <ResumeExperienceInterval startDate={startDate} endDate={endDate} interval={interval} />
        </Wrapper>
    );
};

export default translation(ResumeExperienceInfo);
