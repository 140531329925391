import { useMemo } from 'react';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Action, Card, Cell, CellText, FormHelper, VSpacing, VSpacingContainer, fromTree } from '@hh.ru/magritte-ui';
import { TrashOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { selectProfessionalRoleTree } from 'src/models/applicant/resume/blocks/position/edit/selectors';
import { useResumeEditorField } from 'src/models/applicant/resume/editor/form/change';
import { ErrorsMap } from 'src/models/applicant/resume/editor/form/errors';
import EditorField from 'src/pages/ResumeView/redesign/common/Form/EditorField';

import RoleTreeSelector from 'src/pages/ResumeView/redesign/blocks/position/Editor/fields/ProfessionalRole/RoleTreeSelector';

const FIELD_NAME = 'professionalRole';

const TrlKeys = {
    label: 'resume.editor.postion.professionalRole.label',
};

const diffCountErrorKeys = ['LENGTH_GREATER_THAN_MAXIMUM'];
const MAX_SELECTED_COUNT = 5;

const ProfessionalRoleField = translation<{ commonErrors: ErrorsMap }>(({ trls, commonErrors }) => {
    const professionalRoleTree = useSelector(selectProfessionalRoleTree);
    const collection = useMemo(() => fromTree(professionalRoleTree?.items || []), [professionalRoleTree]);

    const { value, onChange, condition, errorMessage, invalid } = useResumeEditorField(
        FIELD_NAME,
        commonErrors,
        diffCountErrorKeys
    );

    const maxSelected = Number(condition?.maxcount ?? MAX_SELECTED_COUNT);

    const handleDelete = (id: string) => {
        onChange(value.filter((role) => role.id !== id));
    };

    const handleChanged = (selectedIds: string[]) => {
        if (!collection?.getTopLevel().length) {
            return;
        }

        const selectedRoles = collection.getExistModels(selectedIds).map(({ id, text }) => ({ id, name: text }));
        onChange(selectedRoles);
    };

    return (
        <EditorField htmlFor="professionalRole" label={trls[TrlKeys.label]}>
            <VSpacingContainer default={12}>
                {value.map(({ name, id }) => (
                    <Card key={id} showBorder stretched borderRadius={16}>
                        <Cell
                            horPadding
                            vertPadding
                            right={
                                <Action
                                    icon={TrashOutlinedSize24}
                                    mode="secondary"
                                    onClick={() => handleDelete(id)}
                                    disablePadding
                                />
                            }
                        >
                            <CellText>{name}</CellText>
                        </Cell>
                    </Card>
                ))}
            </VSpacingContainer>
            <VSpacing default={12} />
            <RoleTreeSelector
                value={value}
                onChange={handleChanged}
                collection={collection}
                maxSelected={maxSelected}
            />
            <FormHelper invalid={invalid} errorMessage={errorMessage} />
        </EditorField>
    );
});

export default ProfessionalRoleField;
