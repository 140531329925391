import { useState, useCallback, useRef } from 'react';

import resumeFavoriteButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_favorite_button_click';
import { Drop, TooltipHover, Button } from '@hh.ru/magritte-ui';
import { HeartFilledSize16, HeartOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'src/components/Notifications/Provider';
import ResumeFolders from 'src/components/ResumeFolders';
import putInFolder from 'src/components/ResumeFolders/putResumesFolder';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    tipTitle: 'topbuttons.resume.favorite',
};

const ResumeAddToFavorite: TranslatedComponent = ({ trls }) => {
    const resumeHash = useSelector(({ resume }) => resume?.hash)!;
    const hasResumeInFolders = useSelector((state) => !!state.resumeFolders?.foldersByResume?.[resumeHash]);
    const simHash = useSelector((state) => state.resume?.simHash);
    const folders = useSelector((state) => state.resumeFolders.foldersInvariants);
    const employerManagerId = useSelector((state) => parseInt(state.employerManager?.id || '', 10));
    const selectedIdsFolder = useSelector((state) => state.resumeFolders?.foldersByResume?.[resumeHash]);
    const resumeId = useSelector((state) => state.resume?.id);
    const vacancyId = useSelector((state) => state.router.location.query.vacancyId);

    const [menuVisible, setMenuVisible] = useState(false);
    const [addedResumeInFolders, setAddedResumeInFolders] = useState(hasResumeInFolders);
    const { addNotification } = useNotification();
    const activatorRef = useRef(null);

    const onButtonClick = useCallback(() => {
        resumeFavoriteButtonClick({ resumeId, vacancyId });
        setMenuVisible(!menuVisible);
    }, [menuVisible, resumeId, vacancyId]);

    const onSuccess = useCallback((hasResumeInFolders: boolean) => {
        setMenuVisible(false);
        setAddedResumeInFolders(hasResumeInFolders);
    }, []);

    return (
        <>
            <Drop visible={menuVisible} placement="top-right" activatorRef={activatorRef} maxWidth={300}>
                <ResumeFolders
                    dataQAPrefix="resume-serp__favourite-popup"
                    onSave={putInFolder({ onSuccess, simHash, employerManagerId, resumeHash }, addNotification)}
                    folders={folders}
                    selectedIdsFolder={selectedIdsFolder}
                    isMagritte
                />
            </Drop>
            <TooltipHover activatorRef={activatorRef} placement="top-center">
                {trls[TrlKeys.tipTitle]}
            </TooltipHover>
            <Button
                size="small"
                mode="secondary"
                icon={addedResumeInFolders ? <HeartFilledSize16 initial="negative" /> : <HeartOutlinedSize16 />}
                onClick={onButtonClick}
                aria-label="resume-favorite"
                data-qa="resume-favorite-button"
                ref={activatorRef}
            >
                {null}
            </Button>
        </>
    );
};
export default translation(ResumeAddToFavorite);
