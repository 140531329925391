import { useRef } from 'react';

import { TooltipHover } from '@hh.ru/magritte-ui';
import { CheckCircleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './phoneVerified.less';

const TrlKeys = {
    verified: 'rb.view.phone.verified',
};
const PhoneVerified: TranslatedComponent = ({ trls }) => {
    const verifiedIconRef = useRef(null);
    return (
        <>
            <span className={styles.iconWrapper} data-qa="mark-valid-icon" ref={verifiedIconRef}>
                <CheckCircleOutlinedSize16 initial="positive" />
            </span>
            <TooltipHover placement="bottom-center" activatorRef={verifiedIconRef}>
                {trls[TrlKeys.verified]}
            </TooltipHover>
        </>
    );
};

export default translation(PhoneVerified);
