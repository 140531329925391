import { createContext, useContext } from 'react';

import { ResumeAttributes } from 'src/models/resume/resume.types';

export interface Payload {
    from: string;
    resume: { _attributes: ResumeAttributes };
}

interface ResumeDeleteContext {
    resume: { _attributes: ResumeAttributes } | null;
    visible: boolean;
    openModal: (payload: Payload) => void;
    closeModal: () => void;
    hideResume: () => void;
    deleteResume: () => void;
}

export const ResumeDeleteModalContext = createContext<ResumeDeleteContext>({
    resume: null,
    visible: false,
    openModal: () => void 0,
    closeModal: () => void 0,
    hideResume: () => void 0,
    deleteResume: () => void 0,
});

export const useResumeDeleteModalContext = (): ResumeDeleteContext => useContext(ResumeDeleteModalContext);
