import {
    selectApplicantResumeAccessType,
    selectApplicantResumeHiddenFields,
} from 'src/models/applicant/resume/blocks/visibility/selectors';
import { areSimpleArraysDifferent } from 'src/models/applicant/resume/editor/lib';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorConfig } from 'src/models/applicant/resume/editor/types/config';

import {
    selectApplicantResumeEditorAccessType,
    selectApplicantResumeEditorHiddenFields,
} from 'src/models/applicant/resume/blocks/visibility/editor/selectors';

export type VisibilityBlockConfig = EditorConfig<Pick<ApplicantResumeEditorFields, 'accessType' | 'hiddenFields'>>;

export const visibilityConfig: VisibilityBlockConfig = {
    init: (store) => {
        return {
            accessType: selectApplicantResumeAccessType(store),
            hiddenFields: selectApplicantResumeHiddenFields(store),
        };
    },

    changed: (store) => {
        const editorAccessType = selectApplicantResumeEditorAccessType(store);
        const resumeAccessType = selectApplicantResumeAccessType(store);

        if (editorAccessType !== resumeAccessType) {
            return true;
        }

        const editorHiddenFields = selectApplicantResumeEditorHiddenFields(store);
        const resumeHiddenFields = selectApplicantResumeHiddenFields(store);

        if (editorHiddenFields?.length !== resumeHiddenFields.length) {
            return true;
        }

        return areSimpleArraysDifferent(resumeHiddenFields, editorHiddenFields);
    },

    prepare: ({ accessType, hiddenFields }) => ({
        accessType: [{ string: accessType }],
        hiddenFields: hiddenFields.map((item) => ({ string: item })),
    }),

    validate: () => {
        return {};
    },
};
