import { AxiosResponse } from 'axios';

import { UpdateBodyType } from 'src/models/applicant/resume/editor/types';
import { EditorFieldError } from 'src/models/applicant/resume/editor/types/error';
import { ApplicantResumeDTO } from 'src/models/applicant/resume/types/resume';
import fetcher from 'src/utils/fetcher';

export interface ApiSuccess {
    resume: ApplicantResumeDTO;
}
export interface ApiConditionsErrors {
    errors: EditorFieldError[];
}

declare global {
    interface FetcherPostApi {
        '/applicant/resume/edit': {
            body: UpdateBodyType;
            response: ApiSuccess | ApiConditionsErrors;
            queryParams: { resume: string; hhtmSource: string };
        };
        '/applicant/deleteresume': {
            body: { hash: string; hhtmFrom: string; hhtmSource: string };
            response: { url: string };
            queryParams: { from: string; hhtmFromLabel: string };
        };
        '/shards/resume/edit/visibility': {
            body: { hash: string; accessType: string };
            response: void;
            queryParams: { from: string; hhtmFromLabel: string };
        };
        '/applicant/resumes/touch': {
            body: { resume: string; undirectable: boolean };
            response: void;
            queryParams: void;
        };
    }
}

export const ResumeApi = {
    update(resume: string, hhtmSource: string, body: UpdateBodyType): Promise<ApiSuccess | ApiConditionsErrors> {
        return fetcher
            .post('/applicant/resume/edit', body, { params: { resume, hhtmSource } })
            .then(({ data }) => data);
    },
    delete(hash: string, hhtmFrom: string, hhtmSource: string): Promise<AxiosResponse<{ url: string }>> {
        return fetcher.postFormData(
            '/applicant/deleteresume',
            { hash, hhtmFrom, hhtmSource },
            { params: { from: 'resume-delete', hhtmFromLabel: 'resume-delete' } }
        );
    },
    hide(hash: string): Promise<AxiosResponse<void>> {
        return fetcher.post(
            '/shards/resume/edit/visibility',
            { hash, accessType: 'no_one' },
            { params: { from: 'resume-delete', hhtmFromLabel: 'resume-delete' } }
        );
    },
    touch(hash: string): Promise<AxiosResponse> {
        // логика поднятия резюме в поиске.
        return fetcher.postFormData('/applicant/resumes/touch', { resume: hash, undirectable: true });
    },
};
