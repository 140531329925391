import { useCallback, useRef } from 'react';

import { ChevronDownOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import Button, { ButtonAppearance, ButtonIconPosition } from 'bloko/blocks/button';
import DropMenu from 'bloko/blocks/drop/Menu';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import ChangeTopicMenuItem, { SendAnalyticsHandler } from 'src/components/ChangeTopicMenuItem';
import translation from 'src/components/translation';
import useToggleState from 'src/hooks/useToggleState';
import { NegotiationLink } from 'src/models/employerNegotiations/negotiationLinks';

import styles from './styles.less';

const TrlKeys = {
    buttonText: 'vacancyResponse.discard',
    shortButtonText: 'vacancyResponse.discard.short',
};

export interface ChangeTopicButtonProps {
    appearance?: ButtonAppearance;
    links: NegotiationLink[];
    showIcon?: boolean;
    showShortText?: boolean;
    stretched?: boolean;
    sendAnalytics?: SendAnalyticsHandler;
    dataQa?: string;
}

const ChangeTopicButton: TranslatedComponent<ChangeTopicButtonProps> = ({
    trls,
    links,
    appearance,
    showIcon,
    showShortText,
    stretched,
    sendAnalytics,
    dataQa,
}) => {
    const hostRef = useRef<HTMLElement>(null);
    const isGtXS = useBreakpoint() !== Breakpoint.XS;
    const [isOpen, toggleOpenState, setOpenState] = useToggleState(false);
    const activatorRef = useRef(null);

    const handleClose = useCallback(() => setOpenState(false), [setOpenState]);

    return (
        <span className={styles.wrapper} ref={hostRef}>
            <DropMenu
                host={isGtXS ? hostRef.current : undefined}
                show={isOpen}
                onClose={handleClose}
                render={() =>
                    links.map(({ name, url }) => (
                        <ChangeTopicMenuItem key={name} name={name} url={url} sendAnalytics={sendAnalytics} />
                    ))
                }
                activatorRef={activatorRef}
            >
                <Button
                    appearance={appearance}
                    stretched={stretched}
                    {...(showIcon && {
                        icon: (
                            <BlokoIconReplaceContainer>
                                <ChevronDownOutlinedSize16 />
                            </BlokoIconReplaceContainer>
                        ),
                        iconPosition: ButtonIconPosition.Right,
                    })}
                    onClick={toggleOpenState}
                    data-qa={dataQa}
                    innerRef={activatorRef}
                >
                    {trls[showShortText ? TrlKeys.shortButtonText : TrlKeys.buttonText]}
                </Button>
            </DropMenu>
        </span>
    );
};

export default translation(ChangeTopicButton);
export type { SendAnalyticsHandler };
