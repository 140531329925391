import { FC, useCallback } from 'react';

import verificationKeySkillBannerButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/verification_key_skill/verification_key_skill_banner_button_click';
import Button, { ButtonType } from 'bloko/blocks/button';

import { getTagName } from 'src/components/getTagName';
import { useSelector } from 'src/hooks/useSelector';
import { ApplicantSkillsVerificationMethod } from 'src/models/applicantSkillsVerificationMethods';

import { HhtmSource } from 'src/components/ResumeView/HtmlSource';

interface ApplicantSkillsVerificationMethodsCardButtonProps {
    method: ApplicantSkillsVerificationMethod;
    onClick: (method: ApplicantSkillsVerificationMethod) => void;
}

const ApplicantSkillsVerificationMethodsCardButton: FC<ApplicantSkillsVerificationMethodsCardButtonProps> = ({
    method,
    onClick,
}) => {
    const hhtmSource = useSelector(({ analyticsParams }) => analyticsParams.hhtmSource) as HhtmSource;
    const handleButtonClick = useCallback(() => {
        verificationKeySkillBannerButtonClick({
            verificationTestName: method.name,
            verificationMethodId: method.id,
            verificationSourceId: method.source.id,
            hhtmSource,
            levelId: method?.verification_objects?.[0]?.level?.rank,
        });
        onClick(method);
    }, [hhtmSource, method, onClick]);

    return (
        <Button stretched type={ButtonType.Button} onClick={handleButtonClick}>
            {getTagName(method)}
        </Button>
    );
};

export default ApplicantSkillsVerificationMethodsCardButton;
