import { FC } from 'react';

import { VSpacingContainer } from '@hh.ru/magritte-ui';
import useBreakpoint from '@hh.ru/magritte-ui-breakpoint/useBreakpoint';

import ResumeEmployerTransitions from 'src/pages/ResumeView/components/ResumeTransitions';
import ResumeVerificationReports from 'src/pages/ResumeView/components/View/ResumeVerificationReports';
import ResponseLetter from 'src/pages/ResumeView/employerRedesign/components/ResponseLetter';
import ResumeAbout from 'src/pages/ResumeView/employerRedesign/components/ResumeAbout';
import ResumeAdditionalInfo from 'src/pages/ResumeView/employerRedesign/components/ResumeAdditionalInfo';
import ResumeCertificates from 'src/pages/ResumeView/employerRedesign/components/ResumeCertificates';
import ResumeCommentsWrapper from 'src/pages/ResumeView/employerRedesign/components/ResumeCommentsWrapper';
import ResumeContacts from 'src/pages/ResumeView/employerRedesign/components/ResumeContacts';
import ResumeCreds from 'src/pages/ResumeView/employerRedesign/components/ResumeCreds';
import ResumeEducation from 'src/pages/ResumeView/employerRedesign/components/ResumeEducation';
import ResumeEducationCourses from 'src/pages/ResumeView/employerRedesign/components/ResumeEducationCourses';
import ResumeEducationTests from 'src/pages/ResumeView/employerRedesign/components/ResumeEducationTests';
import ResumeExperience from 'src/pages/ResumeView/employerRedesign/components/ResumeExperience';
import ResumeHistory from 'src/pages/ResumeView/employerRedesign/components/ResumeHistory';
import ResumeLanguages from 'src/pages/ResumeView/employerRedesign/components/ResumeLanguages';
import ResumeMainInfo from 'src/pages/ResumeView/employerRedesign/components/ResumeMainInfo';
import ResumePortfolio from 'src/pages/ResumeView/employerRedesign/components/ResumePortfolio';
import ResumeRecommendations from 'src/pages/ResumeView/employerRedesign/components/ResumeRecommendations';
import ResumeRelated from 'src/pages/ResumeView/employerRedesign/components/ResumeRelated';
import ResumeSkills from 'src/pages/ResumeView/employerRedesign/components/ResumeSkills';
import ResumeSpecializations from 'src/pages/ResumeView/employerRedesign/components/ResumeSpecializations';
import ResumeViewHistory from 'src/pages/ResumeView/employerRedesign/components/ResumeViewHistory';
import VacancyTests from 'src/pages/ResumeView/employerRedesign/components/VacancyTests';

const ResumeBody: FC = () => {
    const { isMobile } = useBreakpoint();

    return (
        <VSpacingContainer default={24}>
            <ResumeMainInfo />
            {isMobile && <ResumeEmployerTransitions />}
            <ResumeContacts />
            <ResponseLetter />
            <ResumeSpecializations />
            {isMobile && (
                <>
                    <ResumeCommentsWrapper />
                    <VacancyTests />
                    <ResumeHistory />
                    <ResumeViewHistory />
                </>
            )}
            <ResumeExperience />
            <ResumeCreds />
            <ResumeVerificationReports isMagritte />
            <ResumeSkills />
            <ResumeLanguages />
            <ResumeAbout />
            <ResumeRecommendations />
            <ResumePortfolio />
            <ResumeEducation />
            <ResumeEducationCourses />
            <ResumeEducationTests />
            <ResumeCertificates />
            <ResumeAdditionalInfo />
            <ResumeRelated />
        </VSpacingContainer>
    );
};

export default ResumeBody;
