import { DefaultRootState } from 'react-redux';

import { selectResumeStringField } from 'src/models/applicant/resume/selectors';
import { ResumeAccessType } from 'src/models/resume/resumeAccess.types';

export const selectApplicantResumeAccessType = selectResumeStringField<ResumeAccessType>(
    'accessType',
    ResumeAccessType.Everyone
);

export const selectApplicantResumeHiddenFields = (state: DefaultRootState) =>
    state.applicantResume.hiddenFields.map((item) => item.string);
