import { ABOUT_FIELDS } from 'src/models/applicant/resume/blocks/about/edit/config';
import { BUSINESS_TRIP_READINESS_FIELD } from 'src/models/applicant/resume/blocks/additional/edit/config/businessTripReadiness';
import { EMPLOYMENT_FIELD } from 'src/models/applicant/resume/blocks/additional/edit/config/employment';
import { TRAVEL_TIME_FIELD } from 'src/models/applicant/resume/blocks/additional/edit/config/travelTime';
import { WORK_SCHEDULE_FIELD } from 'src/models/applicant/resume/blocks/additional/edit/config/workSchedule';
import { ADDITIONAL_EDUCATION_FIELD } from 'src/models/applicant/resume/blocks/additionalEducation/editor/config';
import { ATTESTATION_EDUCATION_FIELD } from 'src/models/applicant/resume/blocks/attestationEducation/editor/config';
import { CONTATCS_FIELDS } from 'src/models/applicant/resume/blocks/contacts/editor/config';
import { POSITION_FIELDS } from 'src/models/applicant/resume/blocks/position/edit/config';
import { RECOMMENDATION_FIELD } from 'src/models/applicant/resume/blocks/recommendation/editor/config';
import { EditorFormType } from 'src/models/applicant/resume/editor/types';

export const EDITOR_QUERY_KEY = 'edit';

// TODO добавить для полей логику открытия редактирвоания
export const RESUME_FIELDS = [
    // точно в резмюме, только мы еще не сделали эти блоки
    'photo',
    'portfolio',
    'experience',
    'certificate',
    'keySkills',

    // Тут блоки ведут на другие страницы, не факт они придут
    'proftest',

    // этого нигде нет
    'videoResume',
    'postalAddress',

    // это переносим в профиль
    'educationLevel',
    'primaryEducation',
];

const PROFILE_LANGUAGE_FIELD = 'language';
const PROFILE_COMMON_FIELDS = [
    'area',
    'metro',
    'gender',
    'birthday',
    'lastName',
    'firstName',
    'middleName',
    'workTicket',
    'citizenship',
];
const PROFILE_VEHICLE_FIELDS = ['hasVehicle', 'driverLicenseTypes'];
const PROFILE_RELOCATION_FIELDS = ['relocation', 'relocationArea'];
const PROFILE_PREFERRED_WORK_AREAS_FIELDS = ['hasVehicle', 'driverLicenseTypes'];

export function getProfileBlockTypeByField(field: string): string | undefined {
    if (PROFILE_COMMON_FIELDS.includes(field)) {
        return 'common';
    }
    if (PROFILE_RELOCATION_FIELDS.includes(field)) {
        return 'relocation';
    }
    if (PROFILE_VEHICLE_FIELDS.includes(field)) {
        return 'relocation';
    }
    if (PROFILE_PREFERRED_WORK_AREAS_FIELDS.includes(field)) {
        return 'preferredWorkAreas';
    }
    if (PROFILE_LANGUAGE_FIELD === field) {
        return 'language';
    }
    return undefined;
}

export function getBlockTypeByField(field: string): EditorFormType | undefined {
    if (ABOUT_FIELDS.includes(field)) {
        return 'about';
    }
    if (POSITION_FIELDS.includes(field)) {
        return 'position';
    }
    if (CONTATCS_FIELDS.includes(field)) {
        return 'contacts';
    }
    if (TRAVEL_TIME_FIELD === field) {
        return 'travelTime';
    }
    if (EMPLOYMENT_FIELD === field) {
        return 'employment';
    }
    if (WORK_SCHEDULE_FIELD === field) {
        return 'workSchedule';
    }
    if (RECOMMENDATION_FIELD === field) {
        return 'recommendation';
    }
    if (ADDITIONAL_EDUCATION_FIELD === field) {
        return 'additionalEducation';
    }
    if (ATTESTATION_EDUCATION_FIELD === field) {
        return 'attestationEducation';
    }
    if (BUSINESS_TRIP_READINESS_FIELD === field) {
        return 'businessTripReadiness';
    }

    return undefined;
}
