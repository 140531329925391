import { useEffect, useState } from 'react';

import { useSelector } from '@hh.ru/front-static-app';

import { Phone } from 'src/models/applicant/resume/blocks/contacts/types';
import { selectResumeEditorErrors } from 'src/models/applicant/resume/editor/store/selectors';

import { PhoneProxyEventBus } from 'src/models/applicant/resume/blocks/contacts/editor/proxy/eventbus';
import {
    isReadyEvent,
    isChangedEvent,
    isDestroyEvent,
} from 'src/models/applicant/resume/blocks/contacts/editor/proxy/lib';
import { RpfEvent } from 'src/models/applicant/resume/blocks/contacts/editor/proxy/types';

export const usePhoneProxyEventBus = (onChange: (phone: Phone) => void, phone?: Phone): Array<'close' | 'open'> => {
    const [connectionStatus, setStatus] = useState<'close' | 'open'>('close');

    const formErrors = useSelector(selectResumeEditorErrors);
    const fieldFormErrors = formErrors.phone || [];
    const phoneError = fieldFormErrors.find(
        (e) => e.fieldNamePath === '/phone/0' || e.fieldNamePath === '/phone/0/formatted'
    );

    useEffect(() => {
        if (phoneError) {
            PhoneProxyEventBus.sendEvent({ type: 'error', payload: phoneError });
        }
    }, [phoneError]);

    useEffect(() => {
        let cb: EventListener;

        const listener = (event: RpfEvent) => {
            if (connectionStatus === 'open') {
                if (isChangedEvent(event)) {
                    onChange(event.payload);
                }
                if (isDestroyEvent(event)) {
                    setStatus('close');
                    PhoneProxyEventBus.unsubscribe(cb);
                }
            } else if (isReadyEvent(event)) {
                PhoneProxyEventBus.sendEvent({ type: 'init', payload: { ...phone, initial: phone?.formatted } });
                setStatus('open');
            }
        };
        cb = PhoneProxyEventBus.subscribe(listener);

        return () => {
            PhoneProxyEventBus.unsubscribe(cb);
        };
    }, [onChange, connectionStatus, phone]);

    return [connectionStatus];
};
