import { Link, Loader, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { getPreferredPhoneType, CELL_PHONE_TYPE } from 'src/components/ResumeContacts/preferredContacts';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { PhoneType } from 'src/models/applicant/resume/blocks/contacts/types';
import { Phone } from 'src/models/resume/resumeCommon.types';
import useShowContacts from 'src/pages/ResumeView/components/ResumeHeader/Contacts/useShowContacts';

import PhoneTypeAndPreference from 'src/pages/ResumeView/employerRedesign/components/ResumeContacts/PhoneTypeAndPreference';
import PhoneVerified from 'src/pages/ResumeView/employerRedesign/components/ResumeContacts/PhoneVerified';

import styles from './showContacts.less';

const TrlKeys = {
    show: 'resume.field.contacts.show',
};

interface ShowContactsProps {
    phone: Phone;
    setContactsVisibility: (value: boolean) => void;
    onContactsClick?: (value: string) => void;
}
const ShowContacts: TranslatedComponent<ShowContactsProps> = ({
    phone,
    setContactsVisibility,
    onContactsClick,
    trls,
}) => {
    const [showContacts, loading] = useShowContacts(setContactsVisibility, onContactsClick);
    const preferredType = useSelector(({ resume }) => getPreferredPhoneType(resume?.preferredContact.value || ''));
    const isPreferred = !!preferredType && phone.type === preferredType;

    return (
        <div data-qa="resume-contacts-phone">
            <PhoneTypeAndPreference isPreferred={isPreferred} type={phone.type as PhoneType} />
            <VSpacing default={4} />
            <div className={styles.phoneContainer}>
                {phone.type === CELL_PHONE_TYPE && phone.verified && <PhoneVerified />}
                <span>{phone.formatted?.slice(0, 11)} &#8230; </span>
                {loading ? (
                    <Loader size={16} />
                ) : (
                    <Link
                        inline
                        typography="label-3-regular"
                        style="accent"
                        data-qa="response-resume_show-phone-number"
                        onClick={showContacts}
                    >
                        {trls[TrlKeys.show]}
                    </Link>
                )}
            </div>
        </div>
    );
};

export default translation(ShowContacts);
