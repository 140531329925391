import { useRef } from 'react';

import employerResumeViewReportsItemClick from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-32152/employer_resume_view_reports_item_click';
import { employerResumeViewReportsItemTooltipElementShownRaw } from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-32152/employer_resume_view_reports_item_tooltip_element_shown';
import { Card, Cell, CellText, Text, TooltipHover } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import formatDate from 'Modules/formatDate';
import { updateUrl } from 'Modules/url';
import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';

import styles from './styles.less';

interface ReportCardProps {
    resumeId: string;
    resumeHash: string;
    skillLevel: string;
    skillLevelName: string;
    skillName: string;
    skillDate: string;
    skillId: number;
    index: number;
    hasVerificationReport: boolean;
    isMagritte?: boolean;
}

const TrlKeys = {
    reportDate: 'skill.verification.date',
    reportTooltip: 'skill.verification.tooltip',
};

const ReportCard: TranslatedComponent<ReportCardProps> = ({
    resumeId,
    resumeHash,
    skillName,
    skillDate,
    skillLevel,
    skillLevelName,
    skillId,
    index,
    hasVerificationReport,
    isMagritte,
    trls,
}) => {
    const employerId = useSelectorNonNullable((state) => state.employerId);
    const push = usePush();
    const activatorRef = useRef<HTMLDivElement>(null);
    const onAppear = () => {
        employerResumeViewReportsItemTooltipElementShownRaw({
            resumeId,
            employerId,
            position: index,
            skillName,
            skillDate,
        });
    };
    const handleClick = () => {
        employerResumeViewReportsItemClick({
            employerId,
            resumeId,
            skillName,
            skillDate,
            position: index,
        });

        const url = updateUrl(`/resume/${resumeHash}/verification`, { skill_id: skillId, level_id: skillLevel });
        push(url);
    };

    const date = format(trls[TrlKeys.reportDate], {
        '{date}': formatDate(skillDate, 'dd MMMM yyyy'),
    });

    return (
        <>
            {isMagritte ? (
                <div ref={activatorRef}>
                    <Cell
                        onClick={handleClick}
                        disabled={!hasVerificationReport}
                        right={<ChevronRightOutlinedSize24 initial="secondary" />}
                    >
                        <CellText type="title">
                            <AsyncHighlighter>
                                {[skillName, skillLevelName].filter(Boolean).join(' — ')}
                            </AsyncHighlighter>
                        </CellText>
                        <CellText type="subtitle">{date}</CellText>
                    </Cell>
                </div>
            ) : (
                <Card
                    ref={activatorRef}
                    onClick={handleClick}
                    disabled={!hasVerificationReport}
                    showBorder
                    padding={16}
                    borderRadius={16}
                    actionCard
                >
                    <div className={styles.card}>
                        <div>
                            <Text typography={'label-2-regular'} style={'primary'}>
                                {skillName}
                            </Text>
                            <Text typography={'label-3-regular'} style={'secondary'}>
                                {date}
                            </Text>
                        </div>
                        <ChevronRightOutlinedSize24
                            highlighted={'secondary'}
                            disabled={'secondary'}
                            initial={hasVerificationReport ? 'primary' : 'secondary'}
                        />
                    </div>
                </Card>
            )}
            {!hasVerificationReport && (
                <TooltipHover placement={'right-center'} activatorRef={activatorRef} onAppear={onAppear}>
                    {trls[TrlKeys.reportTooltip]}
                </TooltipHover>
            )}
        </>
    );
};

export default translation(ReportCard);
