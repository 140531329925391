import { memo } from 'react';

import { Link, Text, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import { EMAIL as PREFERRED_CONTACT_EMAIL } from 'src/components/ResumeContacts/preferredContacts';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { EMAIL } from 'src/utils/constants/resumeHiddenFields';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    isHidden: 'resume.hiddenFields.email',
    isPreferred: 'rb.view.preferredcontact',
    title: 'resumeView.contacts.email',
};
const ResumeContactsEmail: TranslatedComponent = ({ trls }) => {
    const email = useSelectorNonNullable(({ resume }) => resume.email.value);
    const isHidden = useSelectorNonNullable(({ resume }) => resume.hiddenFields.includes(EMAIL));
    const isPreferred = useSelectorNonNullable(
        ({ resume }) => resume.preferredContact.value === PREFERRED_CONTACT_EMAIL
    );

    if (isHidden) {
        return (
            <Text style="secondary" typography="label-3-regular">
                {trls[TrlKeys.isHidden]}
            </Text>
        );
    }

    return (
        <div data-qa="resume-contact-email">
            <Text style="secondary" typography="label-3-regular">
                {trls[TrlKeys.title]}
                {isPreferred && `${NON_BREAKING_SPACE}— ${trls[TrlKeys.isPreferred]}`}
            </Text>
            <VSpacing default={4} />
            <Link
                style="neutral"
                href={`mailto:${email}`}
                data-qa={isPreferred ? 'resume-contact-preferred' : undefined}
            >
                <AsyncHighlighter>{email}</AsyncHighlighter>
            </Link>
        </div>
    );
};

export default translation(memo(ResumeContactsEmail));
