import { fetchProfessionalRole, fetchRolesCatalog } from 'src/models/applicant/resume/api/dictionaries';
import {
    selectApplicantResumeTitle,
    selectApplicantResumeSalary,
    selectApplicantResumeProfessionalRole,
} from 'src/models/applicant/resume/blocks/position/selectors';
import { isArrayChangedByFn } from 'src/models/applicant/resume/editor/form/change/lib';
import { validateEditorFieldByKey } from 'src/models/applicant/resume/editor/form/validate/index';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorConfig } from 'src/models/applicant/resume/editor/types/config';

import {
    selectRolesCatalog,
    selectResumeEditorTitle,
    selectResumeEditorSalary,
    selectResumeEditorRrofessionalRole,
    selectProfessionalRoleTree,
} from 'src/models/applicant/resume/blocks/position/edit/selectors';

export const POSITION_FIELDS = ['title', 'salary', 'professionalRole'] as const;

type PositionFields = (typeof POSITION_FIELDS)[number];

export type PostitonBlockConfig = EditorConfig<Pick<ApplicantResumeEditorFields, PositionFields>>;

export const positionConfig: PostitonBlockConfig = {
    preload: async (store) => {
        const lang = store.langs?.[0] || 'RU';
        let rolesCatalog = selectRolesCatalog(store);
        let professionalRoleTree = selectProfessionalRoleTree(store);

        if (!rolesCatalog) {
            rolesCatalog = await fetchRolesCatalog(lang);
        }

        if (!professionalRoleTree) {
            professionalRoleTree = await fetchProfessionalRole(lang);
        }

        return {
            rolesCatalog,
            professionalRoleTree,
        };
    },

    init: (store) => {
        const rolesCatalog = selectRolesCatalog(store) || { roles: {} };
        const professionalRole = selectApplicantResumeProfessionalRole(store);

        return {
            title: selectApplicantResumeTitle(store),
            salary: selectApplicantResumeSalary(store),
            professionalRole: professionalRole.map(({ string }) => rolesCatalog.roles[string]),
        };
    },

    changed: (store) => {
        const editorTitle = selectResumeEditorTitle(store);
        const editorSalary = selectResumeEditorSalary(store);
        const editorProfessionalRole = selectResumeEditorRrofessionalRole(store);

        const resumeTitle = selectApplicantResumeTitle(store);
        const resumeSalary = selectApplicantResumeSalary(store);
        const resumeProfessionalRole = selectApplicantResumeProfessionalRole(store);

        return (
            editorTitle !== resumeTitle ||
            editorSalary?.amount !== resumeSalary.amount ||
            editorSalary?.currency !== resumeSalary.currency ||
            isArrayChangedByFn(
                editorProfessionalRole,
                resumeProfessionalRole,
                (a, b) => String(a.id) === String(b.string)
            )
        );
    },

    prepare: ({ title, salary, professionalRole }) => ({
        title: title ? [{ string: String(title) }] : [],
        salary: salary ? [salary] : [],
        professionalRole: professionalRole.map(({ id }) => ({ string: id })),
    }),

    validate: ({ title, salary, professionalRole }, conditions) => {
        return {
            ...validateEditorFieldByKey('title', title, conditions),
            ...validateEditorFieldByKey('salary', salary, conditions),
            ...validateEditorFieldByKey('professionalRole', professionalRole, conditions),
        };
    },
};
