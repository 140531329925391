import { ChangeEvent, useRef } from 'react';

import { translation } from '@hh.ru/front-static-app';
import { TextArea, BottomSheet, BottomSheetFooter, Button, NavigationBar, Action } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

interface Props {
    visible: boolean;
    placeholder: string;
    value: string;
    invalid: boolean;
    onClose: VoidFunction;
    onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

const TrlKeys = {
    title: 'resume.editor.experience.description.title',
    save: 'resume.editor.experience.description.save',
};

const DescriptionMobile = translation<Props>(({ trls, visible, value, placeholder, invalid, onChange, onClose }) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    return (
        <BottomSheet
            visible={visible}
            onClose={onClose}
            height="full-screen"
            onAppear={() => inputRef?.current?.focus()}
            header={
                <NavigationBar
                    title={trls[TrlKeys.title]}
                    right={<Action icon={CrossOutlinedSize24} onClick={onClose} />}
                />
            }
            footer={
                <BottomSheetFooter>
                    <Button mode="primary" style="accent" onClick={onClose}>
                        {trls[TrlKeys.save]}
                    </Button>
                </BottomSheetFooter>
            }
        >
            <TextArea
                layout="fill-both"
                ref={inputRef}
                value={value}
                placeholder={placeholder}
                invalid={invalid}
                onChange={onChange}
            />
        </BottomSheet>
    );
});

export default DescriptionMobile;
