import { RefObject, useCallback } from 'react';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import selectShown from '@hh.ru/analytics-js-events/build/xhh/employer/calls/web_call/call_type_select_element_shown';
import inAppBtnClick from '@hh.ru/analytics-js-events/build/xhh/employer/calls/web_call/in_app_call_button_click';
import phoneCallBtnClick from '@hh.ru/analytics-js-events/build/xhh/employer/calls/web_call/web_call_button_click';
import { translation, TranslatedComponent } from '@hh.ru/front-static-app';
import { Drop, Banner, Text, VSpacingContainer } from '@hh.ru/magritte-ui';
import { SmartphoneOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { HhGrayscaleSize24 } from '@hh.ru/magritte-ui/service';

import { CallType } from 'src/components/Webcall/types';

import CallTypeCard from 'src/components/CallTypeDrop/CallTypeCard';

interface AnalyticsParams {
    hhtmSource: string;
    resumeId: number;
    topicId?: string | null;
}

interface CallTypeDropProps {
    visible: boolean;
    activatorRef: RefObject<HTMLElement>;
    onClose: () => void;
    onCallClick: (callType: CallType) => void;
    analyticsParams: AnalyticsParams;
}

const TrlKeys = {
    banner: 'webcall.callTypeSelect.banner.text',
    appTitle: 'webcall.callTypeSelect.app.title',
    appDescription: 'webcall.callTypeSelect.app.description',
    phoneTitle: 'webcall.callTypeSelect.phone.title',
    phoneDescription: 'webcall.callTypeSelect.phone.description',
    call: 'webcall.button.call',
};

const CallTypeDrop: TranslatedComponent<CallTypeDropProps> = ({
    trls,
    visible,
    activatorRef,
    onClose,
    onCallClick,
    analyticsParams,
}) => {
    const callClick = useCallback(
        (callType: CallType) => {
            onCallClick(callType);
            onClose();
            if (callType === 'user') {
                inAppBtnClick({
                    hhtmSource: analyticsParams.hhtmSource,
                    topicId: analyticsParams.topicId,
                    resumeId: analyticsParams.resumeId,
                });
            }
            if (callType === 'pstn') {
                phoneCallBtnClick({
                    hhtmSource: analyticsParams.hhtmSource,
                    resumeId: analyticsParams.resumeId,
                    topicId: analyticsParams.topicId,
                    fromSelector: true,
                });
            }
        },
        [onClose, onCallClick, analyticsParams]
    );

    return (
        <Drop placement="bottom-left" visible={visible} activatorRef={activatorRef} onClose={onClose} width={450}>
            <ElementShownAnchor
                fn={selectShown}
                hhtmSource={analyticsParams.hhtmSource}
                resumeId={analyticsParams.resumeId}
                topicId={analyticsParams.topicId}
            >
                <VSpacingContainer default={12}>
                    <Banner content={<Text>{trls[TrlKeys.banner]}</Text>} showClose={false} stretched style="neutral" />
                    <CallTypeCard
                        icon={<HhGrayscaleSize24 />}
                        title={trls[TrlKeys.appTitle]}
                        description={trls[TrlKeys.appDescription]}
                        onClick={() => {
                            callClick('user');
                        }}
                        ctaText={trls[TrlKeys.call]}
                    />
                    <CallTypeCard
                        icon={<SmartphoneOutlinedSize24 />}
                        title={trls[TrlKeys.phoneTitle]}
                        description={trls[TrlKeys.phoneDescription]}
                        onClick={() => {
                            callClick('pstn');
                        }}
                        ctaText={trls[TrlKeys.call]}
                    />
                </VSpacingContainer>
            </ElementShownAnchor>
        </Drop>
    );
};

export default translation(CallTypeDrop);
