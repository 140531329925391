import { selectResumeExperience } from 'src/models/applicant/resume/blocks/experience/selectors';
import { validateEditorFieldByKey } from 'src/models/applicant/resume/editor/form/validate/index';
import { isNewArrayField, isChangedByKeys, updateItemById } from 'src/models/applicant/resume/editor/lib';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorConfig, PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { Experience } from 'src/models/resume/resumeCommon.types';

import {
    getSelectedExperienceByIndex,
    isNewExperienceChanged,
    transformExperienceToSave,
} from 'src/models/applicant/resume/blocks/experience/editor/lib';
import { selectResumeEditorExperience } from 'src/models/applicant/resume/blocks/experience/editor/selectors';

export type ExperienceBlockConfig = EditorConfig<Pick<ApplicantResumeEditorFields, 'experience'>>;

const isExperienceChanges = isChangedByKeys<Partial<Experience>>(['position', 'description', 'startDate', 'endDate']);

export const experienceConfig: ExperienceBlockConfig = {
    init: (store) => {
        return {
            experience: getSelectedExperienceByIndex(store),
        };
    },

    changed: (store) => {
        const editorExperience = selectResumeEditorExperience(store);
        const resumeExperiences = selectResumeExperience(store);

        if (!editorExperience) {
            return false;
        }

        if (isNewArrayField(editorExperience)) {
            return isNewExperienceChanged(editorExperience);
        }

        const resumeExperience = resumeExperiences.find(({ id }) => id === editorExperience.id);

        if (!resumeExperience) {
            return false;
        }

        return isExperienceChanges(resumeExperience, editorExperience);
    },

    prepare: ({ experience }, store) => {
        const resumeExperience = selectResumeExperience(store);
        let experienceToSave: Experience[] = [];

        if (isNewArrayField(experience)) {
            experienceToSave = [...resumeExperience, experience];
        } else {
            experienceToSave = updateItemById(resumeExperience, experience);
        }

        return {
            experience: transformExperienceToSave(experienceToSave),
        };
    },

    validate: ({ experience }, conditions) => ({
        ...validateEditorFieldByKey('experience', experience, conditions),
    }),

    delete: (store, payload) => {
        const experience = selectResumeExperience(store);

        const { index } = payload as PayloadType<{ index: number }>;

        return { experience: transformExperienceToSave(experience.filter((_, i) => i !== index)) };
    },
};
