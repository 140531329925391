import { useState, useCallback, useRef, Ref } from 'react';
import { useDispatch } from 'react-redux';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import hhCallBtnClick from '@hh.ru/analytics-js-events/build/xhh/employer/calls/web_call/hh_call_button_click';
import webCallBtnClick from '@hh.ru/analytics-js-events/build/xhh/employer/calls/web_call/web_call_button_click';
import webCallTipShown from '@hh.ru/analytics-js-events/build/xhh/employer/calls/web_call/web_call_tip_element_shown';
import { useBreakpoint, TooltipHover, Button as MagritteButton } from '@hh.ru/magritte-ui';
import { LockClosedOutlinedSize16, PhoneOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import Button, { ButtonAppearance, ButtonKind, ButtonIconPosition } from 'bloko/blocks/button';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Notices, { MarkAsViewedEvent } from 'Modules/Notices';
import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import CallTypeDrop from 'src/components/CallTypeDrop/CallTypeDrop';
import { selectorCanCommunicateWithApplicant } from 'src/components/HiringManager/utils/selectorCanCommunicateWithApplicant';
import MagritteInfotip from 'src/components/Infotip/MagritteInfotip';
import ResumeBlockButtonWrapper from 'src/components/ResumeView/ResumeBlockButtonWrapper';
import WebcallWrapper from 'src/components/Webcall/WebcallWrapper';
import { CallType } from 'src/components/Webcall/types';
import useWebcall from 'src/components/Webcall/useWebcall';
import translation from 'src/components/translation';
import useExperiment from 'src/hooks/useExperiment';
import useIsClient from 'src/hooks/useIsClient';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';

import styles from './index.less';

const infoTipAction = makeSetStoreField('infoTip');

const TrlKeys = {
    call: 'webcall.button.call.explained',
    title: 'webcall.banner.title',
    description: 'webcall.banner.description',
    disabledStatus: 'webcall.status.disabled.title',
};

const INFO_TIP_NAME = 'webcall-resume-button';

const WebcallButton: TranslatedComponent<{
    isResumeViewMagritte?: boolean;
    onContactsClick?: (triggerName: string) => void;
}> = ({ onContactsClick, isResumeViewMagritte = false, trls }) => {
    const dispatch = useDispatch();
    const [webcallShown, setWebcallShown] = useState(false);
    const { isGtS } = useBreakpoint();
    const resumeId = useSelector(({ resume }) => resume?.id || 0);
    const inAppCallEnabled = useSelector(({ resume }) => resume?.inAppCallEnabled);
    const topicId = useSelector(({ router }) => router.location.query?.t || undefined);
    const isSudo = useSelector(({ actualAccount }) => actualAccount !== null);
    const isClient = useIsClient();
    const hoverTipRef = useRef(null);
    const callTypeDropActivatorRef = useRef(null);
    const [isCallTypeDropVisible, showCallTypeDropVisible, hideCallTypeDropVisible] = useOnOffState(false);

    const resumeIdNumber = Number(resumeId);
    const widgetId = 'webcall-front';

    const canCommunicate = useSelector(selectorCanCommunicateWithApplicant);
    const isWebcallMounted = useSelector(({ microFrontends }) => Boolean(microFrontends['webcall-front']));
    const isWebcallLocked = useSelector(({ webcall }) => !webcall.stable);
    const isOpenedInIframe = isClient && window.top !== window;
    const isWebcallAvailable = isWebcallMounted && canCommunicate && !isOpenedInIframe && isGtS;
    const isNewBtnExp = useExperiment('in_app_call_emp', isWebcallAvailable);

    const isMagritte = isNewBtnExp || (isResumeViewMagritte && isWebcallAvailable);

    const isInfotipVisible = useSelector(({ infoTip }) => infoTip.name === INFO_TIP_NAME);
    const tooltipText = `${trls[TrlKeys.title]}. ${trls[TrlKeys.description]}`;

    const handleClick = useCallback(() => {
        if (isSudo) {
            return;
        }
        onContactsClick?.('call');

        if (isInfotipVisible) {
            Notices.markAsViewed(INFO_TIP_NAME);
            dispatch(infoTipAction({}));
        }

        if (inAppCallEnabled && isNewBtnExp) {
            showCallTypeDropVisible();
            hhCallBtnClick({
                resumeId: resumeIdNumber,
                hhtmSource: 'resume',
                topicId,
            });
            return;
        }

        setWebcallShown(true);
        webCallBtnClick({
            resumeId: resumeIdNumber,
            hhtmSource: 'resume',
            topicId,
        });
    }, [
        dispatch,
        isInfotipVisible,
        resumeIdNumber,
        topicId,
        isSudo,
        inAppCallEnabled,
        showCallTypeDropVisible,
        onContactsClick,
        isNewBtnExp,
    ]);

    const { initCall } = useWebcall();

    const handleTypedCallClick = useCallback(
        (callType: CallType) => {
            initCall({
                resumeId: resumeIdNumber,
                source: 'resume',
                topicId,
                callType,
                canSwitchCallType: true,
            });
        },
        [initCall, resumeIdNumber, topicId]
    );

    const button = (
        <MagritteInfotip
            name={INFO_TIP_NAME}
            render={({ activatorRef }) => (
                <div ref={hoverTipRef}>
                    <div
                        ref={activatorRef as Ref<HTMLDivElement>}
                        className={isResumeViewMagritte ? styles.buttonStretched : ''}
                    >
                        {isMagritte ? (
                            <>
                                <MagritteButton
                                    ref={callTypeDropActivatorRef}
                                    onClick={handleClick}
                                    mode="secondary"
                                    size="small"
                                    style={isResumeViewMagritte ? 'neutral' : 'accent'}
                                    disabled={isWebcallLocked}
                                    icon={isResumeViewMagritte ? <PhoneOutlinedSize16 /> : undefined}
                                >
                                    {trls[TrlKeys.call]}
                                </MagritteButton>
                                {inAppCallEnabled && (
                                    <CallTypeDrop
                                        visible={isCallTypeDropVisible}
                                        activatorRef={callTypeDropActivatorRef}
                                        onClose={hideCallTypeDropVisible}
                                        onCallClick={handleTypedCallClick}
                                        analyticsParams={{
                                            hhtmSource: 'resume',
                                            resumeId: resumeIdNumber,
                                            topicId,
                                        }}
                                    />
                                )}
                            </>
                        ) : (
                            <Button
                                onClick={handleClick}
                                appearance={ButtonAppearance.Outlined}
                                kind={ButtonKind.Primary}
                                icon={
                                    <span className={styles.iconWrapper}>
                                        <BlokoIconReplaceContainer>
                                            {isWebcallLocked ? <LockClosedOutlinedSize16 /> : <PhoneOutlinedSize16 />}
                                        </BlokoIconReplaceContainer>
                                    </span>
                                }
                                iconPosition={ButtonIconPosition.Right}
                                disabled={isWebcallLocked}
                            >
                                {trls[TrlKeys.call]}
                            </Button>
                        )}
                    </div>
                </div>
            )}
            bannerStyle="contrast"
            showBannerOnMobile={false}
            placement="bottom-center"
            showClose
            closeByClickOutside={false}
            visible={isInfotipVisible}
            markAsViewedEvent={MarkAsViewedEvent.OnHide}
            useDefaultHost
            guardDistanceToActivator={25}
        >
            <ElementShownAnchor fn={webCallTipShown} resumeId={resumeIdNumber} hhtmSource="resume">
                {tooltipText}
            </ElementShownAnchor>
        </MagritteInfotip>
    );
    const renderButton = () =>
        isResumeViewMagritte ? <ResumeBlockButtonWrapper>{button}</ResumeBlockButtonWrapper> : <>{button}</>;

    if (isWebcallAvailable) {
        return (
            <>
                {renderButton()}
                {isWebcallLocked && (
                    <TooltipHover placement="bottom-center" activatorRef={hoverTipRef} maxWidth={300}>
                        {trls[TrlKeys.disabledStatus]}
                    </TooltipHover>
                )}
                {webcallShown && (
                    <WebcallWrapper
                        place={widgetId}
                        onClose={() => setWebcallShown(false)}
                        hhtmSource="resume"
                        resumeId={resumeIdNumber}
                        topicId={topicId}
                    />
                )}
            </>
        );
    }

    return null;
};

export default translation(WebcallButton);
