import { ComponentType } from 'react';
import { useDispatch } from 'react-redux';

import { Card, VSpacingContainer, Text, useBreakpoint } from '@hh.ru/magritte-ui';

import translation from 'src/components/translation';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import ResumeContactsEditor from 'src/pages/ResumeView/redesign/blocks/contacts/Editor/index';

import EditorButton from 'src/pages/ResumeView/redesign/blocks/contacts/Card/EditorButton';
import ContactsEmail from 'src/pages/ResumeView/redesign/blocks/contacts/Card/Email';
import ContactsPersonalSite from 'src/pages/ResumeView/redesign/blocks/contacts/Card/PersonalSite';
import ContactsPhones from 'src/pages/ResumeView/redesign/blocks/contacts/Card/Phones';

import styles from './styles.less';

const TrlKeys = {
    label: 'applicant.resume.contacts.label',
};

const ApplicantResumeContacts: ComponentType = translation(({ trls }) => {
    const dispatch = useDispatch();

    const { isMobile } = useBreakpoint();

    const handleEdit = () => dispatch(openResumeEditorModal('contacts'));

    return (
        <>
            <VSpacingContainer default={12}>
                <Text typography="label-2-regular" style="secondary">
                    {trls[TrlKeys.label]}
                </Text>
                <Card stretched padding={isMobile ? 12 : 24} borderRadius={24} showBorder>
                    <div className={styles.card}>
                        <div className={styles.cardContent}>
                            <ContactsPhones />
                            <ContactsEmail />
                            <ContactsPersonalSite />
                        </div>
                        <EditorButton onClick={handleEdit} />
                    </div>
                </Card>
            </VSpacingContainer>
            <ResumeContactsEditor />
        </>
    );
});

export default ApplicantResumeContacts;
