import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ConversionDateInterval from 'src/components/Conversion/DateInterval';
import MonthName from 'src/components/HumanDate/MonthName';
import TagWithContrastText from 'src/components/TagWithContrastText';
import translation from 'src/components/translation';
import { ShortExperience } from 'src/models/resume/resumeCommon.types';
import { splitStringDate } from 'src/pages/ResumeView/employerRedesign/utils';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from './styles.less';

const TrlKeys = {
    now: 'resume.experienceInterval.now',
};

type ResumeExperienceIntervalProps = Pick<ShortExperience, 'startDate' | 'endDate' | 'interval'>;

const ResumeExperienceInterval: TranslatedComponent<ResumeExperienceIntervalProps> = ({
    startDate,
    endDate,
    interval,
    trls,
}) => {
    const start = splitStringDate(startDate);
    const end = endDate && splitStringDate(endDate);

    return (
        <div className={styles.wrapper}>
            <TagWithContrastText>
                <ConversionDateInterval years={interval?.years || 0} months={interval?.months || 0} />
            </TagWithContrastText>
            <Text typography="paragraph-3-regular">
                <MonthName month={start.month} />
                {`${NON_BREAKING_SPACE}${start.year} — `}
                <span>
                    {end ? (
                        <>
                            <MonthName month={end.month} />
                            {`${NON_BREAKING_SPACE}${end.year}`}
                        </>
                    ) : (
                        trls[TrlKeys.now]
                    )}
                </span>
            </Text>
        </div>
    );
};

export default translation(ResumeExperienceInterval);
