import useExperiment from 'src/hooks/useExperiment';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import EmployerResumeView from 'src/pages/ResumeView/employerRedesign/ResumeView';
import ResumeViewOld from 'src/pages/ResumeView/old/ResumeView';
import ResumeViewNew from 'src/pages/ResumeView/redesign/ResumeView';

const ResumeView = (props) => {
    const userType = useSelector(({ userType }) => userType);
    const isEmployer = UserType.Employer === userType;
    const isPrintVersion = useSelector(({ printVersion }) => printVersion.isPrintVersion);
    const isEmployerMagritteExp = useExperiment('magritte_employer_resume_view');
    const isMagritteExp = useExperiment('magritte_applicant_resume_view');
    if (isEmployer && isEmployerMagritteExp && !isPrintVersion) {
        return <EmployerResumeView />;
    }
    if (isMagritteExp) {
        return <ResumeViewNew />;
    }
    return <ResumeViewOld {...props} />;
};

export default ResumeView;
